// TODO: 修改键时，需要全局搜索，注意多处使用同一个键。
export default {

    //空值
    "en号": "",
    "原料确认": "确认",

    //登录
    // "账号密码登录": "账号密码登录",
    // "手机号码登录": "手机号码登录",
    // "请输入用户名": "请输入用户名",
    // "请输入密码": "请输入密码",
    // "登录": "登录",
    // "重置": "重置",
    // "还没有账号，去": "还没有账号，去",
    // "注册": "注册",
    // //个人资料
    // "用户名": "用户名",
    // "个人资料": "个人资料",
    // "修改密码": "修改密码",
    // "修改手机号": "修改手机号",
    // "用户管理": "用户管理",
    // "退出登录": "退出登录",
    // //原料库配置
    // "原料库配置": "原料库配置",
    // //原料查询
    // "原料名称": "原料名称",
    // "CAS编号": "CAS编号",
    // "描述": "描述",
    // "查询": "查询",
    // "返回": "返回",
    // //原料列表
    // "CAS编号/原料编号": "CAS编号/原料编号",
    // "原料名称": "原料名称",
    // "描述": "描述",
    // "光谱图": "光谱图",
    // "操作": "操作",
    // "编辑": "编辑",
    // "新增": "新增",
    // "删除": "删除",
    // //模拟
    // "上一步": "上一步",
    // "开始模拟": "开始模拟",
    // "结束": "结束",
    // "暂停": "暂停",
    // "树根图": "树根图",
    // "动作列表": "动作列表",
    // "实验进度": "实验进度",
    // "动作参数": "动作参数",
    // "反应器参数": "反应器参数",
    // //合成中数字孪生
    // "实验进度": "实验进度",
    // "日志": "日志",
    // "机器参数": "机器参数",
    // "模块名称": "模块名称",
    // "设定值": "设定值",
    // "当前值": "当前值",
    // "通道": "通道",
    // "通道1": "通道1",
    // "通道2": "通道2",
    // "通道3": "通道3",
    // "通道4": "通道4",
    // "通道5": "通道5",
    // "通道6": "通道6",
    // "振荡模块": "振荡模块",
    // "已连接": "已连接",
    // "未连接": "未连接",
    // //合成中树根图
    // "树根图": "树根图",
    // "当前时间切片": "当前时间切片",
    // "瓶子信息": "瓶子信息",
    // "持续时间": "持续时间",
    // "运行时间": "运行时间",
    // "总运行时间": "总运行时间",
    // "液体信息": "液体信息",
    // "瓶内液体": "瓶内液体",
    // "动作": "动作",
    // "参数": "参数",
    // "图例": "图例",
    // "序号": "序号",
    // "颜色及含义": "颜色及含义",
    // "步骤时间及其他信息": "步骤时间及其他信息",
    // "步骤操作": "步骤操作",
    // "模块信息": "模块信息",
    // "表征数据": "表征数据",
    // // 设备列表页面
    // '工作站': '工作站',
    // '异木棉MC01': '异木棉MC01',
    // '高通量高频药化反应工作站': '高通量高频药化反应工作站',
    // '异木棉HT01': '异木棉HT01',
    // '高通量水热合成工作站': '高通量水热合成工作站',
    // '异木棉PC01': '异木棉PC01',
    // '高通量光催化评价工作站': '高通量光催化评价工作站',
    // '异木棉MC02': '异木棉MC02',
    // '高通量高频药化反应工作站': '高通量高频药化反应工作站',
    // '异木棉HT02': '异木棉HT02',
    // '高通量水热合成工作站': '高通量水热合成工作站',
    // '木棉FS01': '木棉FS01',
    // '模块化连续流合成工作站': '模块化连续流合成工作站',
    // '木棉QD01': '木棉QD01',
    // '量子点连续合成工作站': '量子点连续合成工作站',
    // '空闲中': '空闲中',
    // '运行中': '运行中',
    // // 配方列表页面
    // '返回': '返回',
    // '全部配方': '全部配方',
    // '全部分类': '全部分类',
    // '摘要': '摘要',
    // '时间': '时间',
    // '开始': '开始',
    // '配置': '配置',
    // '详情': '详情',
    // '删除': '删除',
    // '新建配方': '新建配方',
    // '上传配方': '上传配方',
    // '手动创建配方': '手动创建配方',
    // '配方分类': '配方分类',
    // '配方名称': '配方名称',
    // 'AI创建配方': 'AI创建配方',
    // '输入DOI/输入内容': '输入DOI/输入内容',
    // '上传配方': '上传配方',
    // '关闭': '关闭',
    // '提交': '提交',
};



