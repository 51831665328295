import React, { lazy, Suspense } from "react";
import { nanoid } from "nanoid";

// 首页
// import Home from '~/views/home/index.jsx'

const SmartLabTh = lazy(() => import("~/views/dataView-th/smartLabTh"));
const DigitalTwinHFCR = lazy(() =>
  import("~/views/dataView-th/digital-twin-HFCR")
);

const DigitalTwinPC = lazy(() => import("~/views/dataView-th/digital-twin-PC"));

const DigitalTwinZZTA = lazy(() =>
  import("~/views/dataView-th/digital-twin-ZZTA")
);

const DigitalTwinZZTB = lazy(() =>
  import("~/views/dataView-th/digital-twin-ZZTB")
);

const TreeRootZZTA = lazy(() => import("~/views/dataView-th/tree-root-ZZTA"));

const DigitalTwinSyn = lazy(() =>
  import("~/views/dataView-th/digitalTwin-syn")
);
const TestSSE = lazy(() => import("~/views/demo/TestSSE"));

const MultFlow = [
  // 机器数字孪生
  {
    path: "/digital-twin-HFCR",
    key: nanoid(),
    element: <DigitalTwinHFCR />,
    name: "高通量药化设备",
  },
  {
    path: "/testSSE",
    element: <TestSSE />,
  },
  {
    path: "/digital-twin-PC",
    key: nanoid(),
    element: <DigitalTwinPC />,
    name: "高通量光催化设备",
  },
  {
    path: "/digital-twin-ZZTA",
    key: nanoid(),
    element: <DigitalTwinZZTA />,
    name: "高通量水热设备(A型）",
  },
  {
    path: "/digital-twin-ZZTB",
    key: nanoid(),
    element: <DigitalTwinZZTB />,
    name: "高通量水热设备(B型）",
  },
  // 树根图
  // {
  //     path: '/tree-root-HFCR', key: nanoid(), element: <DigitalTwinHFCR />, name: '高通量药化树根',
  // },
  // {
  //     path: '/tree-root-PC', key: nanoid(), element: <DigitalTwinPC />, name: '高通量光催化树根',
  // },
  {
    path: "/tree-root-ZZTA",
    key: nanoid(),
    element: <TreeRootZZTA />,
    name: "高通量水热树根(A型）",
  },
  {
    path: "/digitalTwin-syn",
    key: nanoid(),
    element: <DigitalTwinSyn />,
    name: "合成中数字孪生",
  },
  {
    path: "/smartLabTh",
    key: nanoid(),
    element: <SmartLabTh />,
    name: "智慧实验室",
  },
  // {
  //     path: '/tree-root-ZZTB', key: nanoid(), element: <DigitalTwinZZTB />, name: '高通量水热树根(B型）',
  // },
];

export default MultFlow;
