import { LineSegments } from "three/src/objects/LineSegments.js";
import { LineBasicMaterial } from "three/src/materials/LineBasicMaterial.js";
import { Float32BufferAttribute } from "three/src/core/BufferAttribute.js";
import { BufferGeometry } from "three/src/core/BufferGeometry.js";
import { Color } from "three/src/math/Color.js";

class MyGridHelper extends LineSegments {
  /**
   * 根据官方修改版-创建网格辅助线
   * @param {Number} num_x x轴方向网格格子的个数
   * @param {Number} num_z z轴方向网格格子的个数
   * @param {Number} size_w 单个格子的宽度
   * @param {Number} size_h 单个格子的高度
   * @param {Number} gridColor 网格的线条颜色
   */
  constructor(
    num_x = 10,
    num_z = 10,
    size_w = 10,
    size_h = 10,
    gridColor = 0x888888
  ) {
    num_x = Number(num_x);
    num_z = Number(num_z);
    size_w = Number(size_w);
    size_h = Number(size_h);

    gridColor = new Color(gridColor);

    const halfz = num_z * 0.5 * size_h;
    const halfx = num_x * 0.5 * size_w;
    const stepx = size_w;
    const stepz = size_h;

    const vertices = [],
      colors = [];

    // x方向的线
    for (let i = 0, j = 0, k = -halfz; i <= num_z; i++, k += stepz) {
      vertices.push(-halfx, 0, k, halfx, 0, k);

      const color = gridColor;

      color.toArray(colors, j);
      j += 3;
      color.toArray(colors, j);
      j += 3;
      color.toArray(colors, j);
      j += 3;
      color.toArray(colors, j);
      j += 3;
    }

    // z方向的线
    for (let i = 0, j = 0, k = -halfx; i <= num_x; i++, k += stepx) {
      vertices.push(k, 0, -halfz, k, 0, halfz);

      const color = gridColor;

      color.toArray(colors, j);
      j += 3;
      color.toArray(colors, j);
      j += 3;
      color.toArray(colors, j);
      j += 3;
      color.toArray(colors, j);
      j += 3;
    }

    // console.log(vertices);

    const geometry = new BufferGeometry();
    geometry.setAttribute("position", new Float32BufferAttribute(vertices, 3));
    geometry.setAttribute("color", new Float32BufferAttribute(colors, 3));

    const material = new LineBasicMaterial({
      vertexColors: true,
      toneMapped: false,
    });

    super(geometry, material);

    this.type = "GridHelper";
  }

  // /**
  //  * 这个方法是传入网格的整体大小和x、z方向上的份数,进行创建
  //  * 根据官方修改版-创建网格辅助线
  //  * @param {*} size_w x轴方向的长度(宽)
  //  * @param {*} size_h z轴方向的长度(高)
  //  * @param {*} divisions_x x轴方向等分的份数
  //  * @param {*} divisions_z z轴方向等分的份数
  //  * @param {*} gridColor 网格的线条颜色
  //  */
  // constructor( size_w = 10, size_h = 10, divisions_x = 10,divisions_z = 10, gridColor = 0x888888 ) {

  // 	gridColor = new Color( gridColor );

  // 	const max_z = size_h / 2
  // 	const max_x = size_w / 2
  // 	const stepx = size_w / divisions_x
  // 	const stepy = size_h / divisions_z

  // 	const vertices = [], colors = [];

  // 	// x方向的线
  // 	for ( let i = 0, j = 0, k = - max_z; i <= divisions_z; i ++, k += stepy ) {

  // 		vertices.push( - max_x, 0, k, max_x, 0, k );

  // 		const color = gridColor;

  // 		color.toArray( colors, j ); j += 3;
  // 		color.toArray( colors, j ); j += 3;
  // 		color.toArray( colors, j ); j += 3;
  // 		color.toArray(colors, j); j += 3;
  // 	}

  // 	// z方向的线
  // 	for ( let i = 0, j = 0, k = - max_x; i <= divisions_x; i ++, k += stepx ) {
  // 		vertices.push( k, 0, - max_z, k, 0, max_z );
  // 	}

  // 	const geometry = new BufferGeometry();
  // 	geometry.setAttribute( 'position', new Float32BufferAttribute( vertices, 3 ) );
  // 	geometry.setAttribute( 'color', new Float32BufferAttribute( colors, 3 ) );

  // 	const material = new LineBasicMaterial( { vertexColors: true, toneMapped: false } );

  // 	super( geometry, material );

  // 	this.type = 'GridHelper';

  // }

  dispose() {
    this.geometry.dispose();
    this.material.dispose();
  }
}

export { MyGridHelper };
