const moduleAxisMap = {
    'A': { x: -475, y: 4, betweenX: 50, betweenY: 50, col: 1 },
    //
    'B': { x: -350, y: 360, betweenX: 0, betweenY: 0, col: 1 },
    //振荡
    'C': { x: -380, y: 25, betweenX: 60, betweenY: 50, col: 2 },
    //加热
    'D': { x: -220, y: 295, betweenX: 40, betweenY: 35, col: 2 },
    'E': { x: -220, y: 165, betweenX: 40, betweenY: 35, col: 2 },
    'F': { x: -220, y: 35, betweenX: 40, betweenY: 35, col: 2 },
    //加热
    'G': { x: -100, y: 295, betweenX: 40, betweenY: 35, col: 2 },
    'H': { x: -100, y: 165, betweenX: 40, betweenY: 35, col: 2 },
    'I': { x: -100, y: 35, betweenX: 40, betweenY: 35, col: 2 },
    //试剂瓶区
    'J': { x: 0, y: 220, betweenX: 40, betweenY: 37, col: 3, offsetX: 0, offsetY: -220 },
    'K': { x: 0, y: 0, betweenX: 40, betweenY: 37, col: 3, offsetX: 0, offsetY: 0 },
    //试剂瓶区
    'L': { x: 120, y: 220, betweenX: 40, betweenY: 37, col: 3, offsetX: -120, offsetY: -220 },
    'M': { x: 120, y: 0, betweenX: 40, betweenY: 37, col: 3, offsetX: -120, offsetY: 0 },
    //
    'N': { x: -262 + 3, y: -170 - 3, betweenX: 40, betweenY: 35, col: 2, },
    'O': { x: -69, y: -2, betweenX: 22, betweenY: 20, col: 5, offsetX: 0, offsetY: 0 },
    'P': { x: -39.5 + 4, y: -177.5, betweenX: 35, betweenY: 32, col: 3 },

    'grab': { x: 14.000, y: 265.000, betweenX: 0, betweenY: 0, col: 1 },
    'tip': { x: 69, y: 2, betweenX: 0, betweenY: 0, col: 1 },

}

export { moduleAxisMap }