import * as THREE from 'three'
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

const originMaterial = {
    color: 'rgb(192,192,192)',
    metalness: 1.0,
    roughness: 0.6,
    // reflectivity: 0.5,
}

const fixModelParam = (getFrame, position, type) => {
    let object = getFrame
    object.position.set(position[0], position[1], position[2])
    object.scale.set(1, 1, 1.5);  // 设置模型缩放
    if (type === 'adapterS4' || type === 'raman2' || type === 'lls' || type === 'threeRoad') {
        object.traverse(
            child => {
                child.geometry && (child.material = new THREE.MeshPhysicalMaterial(originMaterial))
            }
        )
    }
    switch (type) {
        case 'lcms':
            object.scale.set(0.15, 0.15, 0.1)
            object.position.set(-2.5 - 4 + position[0], 5 + position[1], 35 + position[2])
            break;
        case 'threeRoad':
            object.position.set(-6 + position[0], -5 + position[1], 35 + position[2])
            break;
        case 'adapterS4':
            object.rotation.y = 1 * Math.PI
            object.position.set(-3 + position[0], position[1] + 1, 35 + position[2] - 1)
            break;
        case 'raman2':
            object.rotation.y = -1 * Math.PI
            object.rotation.z = 0.5 * Math.PI
            object.position.set(-5.45 + position[0], position[1] + 7, 35.3 + position[2])

            break;
        case 'RE_LT_SL':
            object.rotation.z = 0.5 * Math.PI
            object.position.set(-15 + position[0], position[1] - 7, 36.5 + position[2])
            break;
        //PTEE高温反应器
        case 'RE_HT_LQ':
            object.rotation.z = -0.5 * Math.PI
            object.position.set(-3.6 + position[0], position[1] - 16.8, 31.3 + position[2])

            break;
        case 'RE_XT_SL':
            object.rotation.z = 0.5 * Math.PI
            object.position.set(7.5 + position[0], position[1] - 8, 41 + position[2])
            break;
        case 'RE_LT_LQ':
            object.rotation.z = 0.5 * Math.PI
            object.position.set(5.5 + position[0], position[1] - 7, 37.3 + position[2])
            break;
        default:
            break;
    }

    object.rotation.x -= Math.PI * 0.5;
    object.position.y -= 1.5;
    return object

}


const loadTexture = () => {
    let textureLoader2 = new THREE.TextureLoader();
    // for (let i = 0; i < 13 * 27; i++) {
    return textureLoader2.load('https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/static/model/darkwhite.png')
}

const css2DLabel = (labelDiv, name) => {
    const moonDiv = labelDiv.cloneNode()
    moonDiv.style.display = "block"
    const moonLabel = new CSS2DObject(moonDiv);
    switch (name) {
        case 'lcms':
            moonLabel.position.z += 35
            break;
        case 'constantPump':
            moonLabel.position.x += 2
            moonLabel.position.z -= 3

            break;
        case 'raman2':
            moonLabel.position.z += 4
            break;
        case 'RE_LT_SL':
            break;
        case 'RE_HT_LQ':
            moonLabel.position.z += 22
            break;
        case 'RE_XT_SL':
            break;
        case 'RE_LT_LQ':
            break;
        default:
            break;
    }
    return moonLabel
}

export { originMaterial, fixModelParam, loadTexture, css2DLabel }