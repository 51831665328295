import React, { lazy, Suspense } from "react";

import { Navigate } from "react-router-dom";

/**
 * 通用页面
 */

// 登录
const Login = lazy(() => import("~/views/login"));

// 404
const Error404 = lazy(() => import("~/views/error/404"));
const HardwareTest = lazy(() => import("~/views/hardwareTest/hardwareTest"));

const commonRoutes = [
  // 通用路由
  {
    path: "*",
    element: (
      <Suspense>
        <Navigate to="/404" replace />
      </Suspense>
    ),
  },
  {
    path: "/404",
    element: (
      <Suspense>
        <Error404 />
      </Suspense>
    ),
  },
  {
    path: "/403",
    element: (
      <Suspense>
        <Error404 />
      </Suspense>
    ),
  },
  {
    path: "/405",
    element: (
      <Suspense>
        <Error404 />
      </Suspense>
    ),
  },
  {
    path: "/login",
    element: (
      <Suspense>
        <Login />
      </Suspense>
    ),
  },
  {
    path: "/hardwareTest",
    element: (
      <Suspense>
        <HardwareTest />
      </Suspense>
    ),
  },
];

export default commonRoutes;
