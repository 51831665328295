import axios from "axios";
import Qs from "qs";
import { json } from "react-router";
import { store } from "~/store";
import { goto, clearLocalStorage } from "~/utils/jstools";
// import { Navigate, useLocation } from 'react-router-dom'
// 重定向到登录页后重置用户信息
// 重定向阈值
// let isRedirect = true
const redirect = () => {
  // if (!isRedirect) return
  // const navigate = useNavigate();
  // console.log('重定向到登录页后重置用户信息1111', window.location, window.location.hash.includes('/login'))

  if (window.location.hash.includes("/login")) return;
  // console.log('重定向到登录页后重置用户信息2222', window.location, window.location.hash.includes('/login'))
  clearLocalStorage();
  // window.href(`#/login?path=${encodeURIComponent(window.location.hash)}`, '_self')
  // isRedirect = false
  goto(`/login?path=${encodeURIComponent(window.location.hash.split("#")[1])}`);
};
// 把请求设置为ajax请求，可以返回200的状态码，从中取出timeout的状态（如果requestedWith为null，则为同步请求。）
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
// 解决IE浏览器请求使用缓存问题
// axios.defaults.headers['Cache-Control'] = 'no-cache, no-store'
// axios.defaults.headers['Pragma'] = 'no-cache'
// console.log('api', process.env, window.appName)
// axios.defaults.headers['TOKEN'] = 'MFkwEwYHKoZIzj0CAQYIKoEcz1UBgi0DQgAEMDsvZXpO8A9L32a'

const isDev = process.env.NODE_ENV === "development";
// console.log("🚀 ~ file: request.js:20 ~ process.env:", process.env)
const baseURL = isDev
  ? process.env.REACT_APP_BASE_API
  : process.env.REACT_APP_BASE_IP + process.env.REACT_APP_BASE_API;
// 创建axios实例
const service = axios.create({
  baseURL: baseURL, // 基路径
  withCredentials: true, // 跨域请求时发送Cookie
  timeout: 1800000, // 请求超时时间
});
/**
 * 解析 URL 中的查询参数
 *
 * @class UrlSearch
 * @constructor
 * @description 构造函数，用于初始化 UrlSearch 实例
 */
function UrlSearch() {
  var name, value;
  var str = window.location.href; //取得整个地址栏
  var num = str.indexOf("?");
  str = str.substr(num + 1); //取得所有参数   stringvar.substr(start [, length ]
  var arr = str.split("&"); //各个参数放到数组里
  for (var i = 0; i < arr.length; i++) {
    num = arr[i].indexOf("=");
    if (num > 0) {
      name = arr[i].substring(0, num);
      value = arr[i].substr(num + 1);
      this[name] = value;
    }
  }
}
const language = window.localStorage.getItem("language") || "zh-CN";

service.interceptors.request.use(
  (config) => {
    // 接口返回后关闭 loading
    store.dispatch({
      type: "settings",
      loading: true,
    });

    const Request = new UrlSearch();
    //  console.log(Request)
    const userInfo = window.localStorage.getItem("userInfo") || "{}";
    // const language = window.localStorage.getItem("language") || 'zh-CN'
    let token = JSON.parse(userInfo).token || Request.token;

    //将token放到请求头发送给服务器,将tokenkey放在请求头中
    config.headers.token = token;
    //也可以这种写法
    config.headers["Accept-Language"] = language == "en-US" ? "en" : "zh";

    if (config.method === "get") {
      config.data = {
        unused: 0,
      }; //解决get请求添加不上Content-Type
    }
    if (config.method === "post") {
      if (config.isFormData) {
        //表单提交
        config.headers["Content-Type"] =
          "application/x-www-form-urlencoded;charset-utf-8";
        config.data = Qs.stringify(config.data);
      } else if (config.isUploadFile) {
        //文件上传
        config.headers["Content-Type"] = "multipart/form-data";
      }
    }

    return config;
  },
  (error) => {
    // store.commit('requestStore/SUBTRACT_REQUEST_COUNT')
    return Promise.reject(error);
  }
);

// response 拦截
service.interceptors.response.use(
  (response) => {
    // console.log("🚀 ~ response:", response)
    store.dispatch({
      type: "settings",
      loading: false,
    });
    if (
      response.headers.sessionstatus == "timeout" ||
      response.headers.sessionstatus == "re_login"
    ) {
      //  接口状态：timeout，需返回登录页或统一认证登录页
      redirect();
    } else if (response.headers.sessionstatus == "refresh") {
      //  接口状态：refresh，需要替换当前的access_token
      const access_token = response.headers.access_token;
      axios.defaults.headers.common["access_token"] = access_token;
      return Promise.resolve(response.data);
    } else {
      // 后台接口重定向 重定向到登录页
      // if (/login\/index.do/.test(response.request.responseURL)) {
      //   redirect()
      // }
      // isRedirect = true
      return Promise.resolve(response.data);
    }
  },
  (error) => {
    store.dispatch({
      type: "settings",
      loading: false,
    });
    // 错误码樱色
    const errorCodeMap = {
      ECONNABORTED: { code: "5xx", msg: "服务器断开链接" },
      ERR_BAD_RESPONSE: { code: "504", msg: "服务器拒绝链接" },
      ERR_BAD_REQUEST: { code: "400", msg: "请求错误" },
      default: { code: "5xx", msg: "请求错误，请重试" },
    };
    const httpResponseMap = {
      400: { code: 400, msg: "接口调用失败" },
      401: { code: 401, msg: "登录失效，请重新登录" },
      404: { code: 404, msg: "网络请求不存在" },
      500: {
        code: 500,
        msg: language == "en-US" ? "Server Errors" : "服务器错误",
      },
      default: { code: "5xx", msg: "请求错误，请重试" },
    };

    // 优先进行 返回信息处理，相对精确些
    if (
      error?.response &&
      error?.response.status &&
      httpResponseMap[error?.response.status]
    ) {
      // 登录失效，返回登录页
      if (error?.response.status === 401) {
        redirect();
        return Promise.resolve();
      }

      return Promise.resolve(httpResponseMap[error?.response.status]);
    }
    if (error.code && errorCodeMap[error.code]) {
      return Promise.resolve(errorCodeMap[error.code]);
    }

    console.error("error:" + error);
    return Promise.reject({ code: "5xx", msg: "未知错误" });
  }
);

service.param = function (a) {
  var s = [],
    add = function (key, value) {
      // If value is a function, invoke it and return its value
      value = typeof value == "function" ? value() : value == null ? "" : value;
      s[s.length] = encodeURIComponent(key) + "=" + encodeURIComponent(value);
    };

  // If an array was passed in, assume that it is an array of form elements.
  if (typeof a == "object") {
    for (let ele in a) {
      add(ele, a[ele]);
    }
  } else if (typeof a == "function") {
    for (let i = 0; i < a.length; i++) {
      add(a[i].name, a[i].value);
    }
  }
  // Return the resulting serialization
  return s.join("&").replace(/%20/g, "+");
};

export default service;
