import { useState, useEffect, useContext, Fragment } from "react";
import { Button, Row, Col, message } from "antd";
import { nanoid } from "nanoid";
import { listFindItem } from "~/utils/jstools";
import {
  PluginContext,
  CommandContext,
  GlobalDataContext,
} from "../../../utils/reactContext.js";
import "../publicStyle.scss";

import eventBus from "~/utils/hooks/eventBus";

export default function MoveMultiBottleB({ msg, content }) {
  const globalData = useContext(GlobalDataContext);
  const [actionJsonData, setActionJsonData] = useState({});
  useEffect(() => {
    // console.log("🚀 ~ globalData:", globalData, content);
    setActionJsonData(JSON.parse(content || "{}"));
  }, [content]);
  let data = {
    functionCode: "moveLiquidB",
    paramArr: [
      {
        downBottle: { targetBlock: "A" },
        pickBottle: {
          pickList: [
            { blockType: "K", num: "1" },
            { blockType: "K", num: "2" },
          ],
        },
      },
    ],
  };

  if (!globalData?.allTaskTypeListZZT2 || !globalData?.blockTypeListZZT2)
    return <span className="textVal">请进入配方配置页面，进行此操作。</span>;
  const applyAction = () => {
    if (
      eventBus.eventList["AISubmitActionZZT2"] &&
      eventBus.eventList["AISubmitActionZZT2"].length > 0
    ) {
      eventBus.$emit("AISubmitActionZZT2", actionJsonData);
    } else {
      message.error("该操作执行失败");
    }
    // console.log("🚀 ~ applyAction ~ data:", eventBus.eventList);
  };
  return (
    <div className="aiActionView">
      <div className="treeColBox">
        <div className="taskInfo">
          <ul className="paramList">
            <Row>
              <Col span={23}>
                <span className="textVal">批量移动瓶子</span>
              </Col>
              <Col span={23}>
                动作：
                <span className="textVal">夹起瓶子</span>
              </Col>

              {actionJsonData?.paramArr &&
                actionJsonData?.paramArr[0].pickBottle?.pickList &&
                actionJsonData?.paramArr[0].pickBottle?.pickList.map((item) => {
                  return (
                    <Fragment>
                      <Col span={12} key={nanoid()}>
                        <li style={{ display: "flex" }}>
                          <span className="frontShow">区块类型:</span>
                          <span className="textVal">
                            {globalData?.blockTypeListZZT2[item?.blockType]}
                          </span>
                          {/* {paramUnit && <span>{paramUnit}</span>} */}
                        </li>
                      </Col>
                      <Col span={12} key={nanoid()}>
                        <li style={{ display: "flex" }}>
                          <span className="frontShow">瓶子序号:</span>
                          <span className="textVal">{item?.num}号瓶子</span>
                          {/* {paramUnit && <span>{paramUnit}</span>} */}
                        </li>
                      </Col>
                    </Fragment>
                  );
                })}
              <Col span={23}>
                动作：
                <span className="textVal">放下瓶子</span>
              </Col>
              {actionJsonData?.paramArr &&
                actionJsonData?.paramArr[0].downBottle && (
                  <Col span={12} key={nanoid()}>
                    <li style={{ display: "flex" }}>
                      <span className="frontShow">区块类型:</span>
                      <span className="textVal">
                        {
                          globalData?.blockTypeListZZT2[
                            actionJsonData?.paramArr[0].downBottle?.targetBlock
                          ]
                        }
                      </span>
                    </li>
                  </Col>
                )}
            </Row>
          </ul>
        </div>
      </div>
      <div className="posBtn">
        <Button type="primary" onClick={applyAction}>
          应用
        </Button>
      </div>
    </div>
  );
}
