import { useState, useEffect, useContext, useCallback, useRef } from "react";
import { AutoComplete, Button, List, message } from "antd";
import "./index.scss";
// import ConversationList from "./modules/conversationList";
// import GeneFormula from "./modules/geneFormulaList";
import { store } from "~/store";
import HistoryView from "./modules/historyView";
import ChatField from "./modules/chatPanel";
import InputField from "./modules/inputField";
import HeaderField from "./modules/headerField";
import Markdown from "./modules/chatPanel/markdown";

import { getSysBasisData } from "~/api/common";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { sendAI, aiDialogue, sendmail } from "./utils/ai-pl-api.js";
import { doiList, chatData } from "./utils/mockData";
import useCommands from "~/utils/hooks/useCommand.js";
import useWebsocket from "~/utils/hooks/useWebsocket.js";
import { nanoid } from "nanoid";
import eventBus from "~/utils/hooks/eventBus";
import { PluginContext, CommandContext } from "../AIPL/utils/reactContext.js";
import { useTranslation } from "react-i18next";

let noOneByOneGenWordMap = ["ff", "user-idea", "info"];
let stepCount = 0;
let timer = null;

let isLoading = false;
export default function ChatAIContainer({ setShowAI }) {
  const { t } = useTranslation();
  const userInfo = JSON.parse(window.localStorage.getItem("userInfo") || "{}");

  const { wsData, closeWebSocket, wsBigData, sendMessage } = useWebsocket({
    url: `/aiws/${userInfo?.id}`, // 此参数为websocket地址
    verify: true, // 此参数控制是否有权限，请求该方法
  });
  // 处理语音录制和是否发送的状态
  const [aiStatus, setAiStatus] = useState({
    nav: "chat",
    isRecording: false,
    messageInfo: {
      msgId: "",
      isSending: false,
    },
    autoComplete: false,
  });
  store.subscribe(() => {
    const { sysStatusInfo } = store.getState();
    // console.log("🚀 ~ store.subscribe ~ sysStatusInfo:", sysStatusInfo);
    setAiStatus((prev) => {
      return {
        ...sysStatusInfo,
      };
    });
    isLoading = sysStatusInfo.messageInfo.isSending;
  });

  const plugins = useContext(PluginContext);
  const commands = useContext(CommandContext);
  // const { SendInput, SendText } = Plugins;
  const [mode, setMode] = useState("normal");
  const { goHome, goDeviceList } = useCommands();
  const [chatList, setChatList] = useState([]);
  const [chatInfo, setChatInfo] = useState({});

  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        anchorElement.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
  };

  const onMessage = async (data) => {
    // AI返回数据为空时，不进行任何操作
    if (!data?.resContent) {
      store.dispatch({
        type: "sysStatusInfo",
        messageInfo: {
          msgId: "",
          isSending: false,
        },
      });
      setChatList((prev) => {
        prev[prev.length - 1] = {
          ...prev[prev.length - 1],
          resContent: JSON.stringify({
            content: [{ data: "返回失败，请重试！", return_type: "error" }],
          }),
          id: nanoid(),
        };
        return [...prev];
      });
      return null;
    }
    store.dispatch({
      type: "sysStatusInfo",
      messageInfo: {
        msgId: data.id,
        isSending: true,
      },
    });
    let streamContent = JSON.parse(data?.resContent);
    // 1、设置聊天记录的id
    setChatInfo({ ...chatInfo, id: data.aiDialogueId });

    // 2、将返回的数据进行清空处理
    if (data?.resContent) data.resContent = JSON.parse(data?.resContent);
    if (data?.resContent?.content) {
      data?.resContent?.content.forEach((item) => {
        // console.log("🚀 ~ data?.resContent?.content.forEach ~ item:", item);
        if (
          ![...Object.keys(plugins), ...noOneByOneGenWordMap].includes(
            item?.return_type
          ) &&
          typeof item.data == "string"
        ) {
          item.data = "";
        } else {
          // noOneByOneGenWordMap === return_type时，关闭发送状态
          store.dispatch({
            type: "sysStatusInfo",
            messageInfo: {
              msgId: "",
              isSending: false,
            },
          });
        }
      });
      data.resContent = JSON.stringify(data?.resContent);
    } else {
      // content为空时，关闭发送状态
      store.dispatch({
        type: "sysStatusInfo",
        messageInfo: {
          msgId: "",
          isSending: false,
        },
      });
      return;
    }

    // console.log("🚀 ~ onMessage ~ streamContent:", streamContent);
    setChatList((prev) => {
      if (prev.length > 0 && prev[prev.length - 1].id === userInfo?.id) {
        prev[prev.length - 1] = data;
        return [...prev];
      } else {
        return [...prev, data];
      }
    });
    // 3、将返回的数据一个一个添加到聊天新记录里
    let allPart = streamContent?.content?.length || 0;
    async function streamText(item, index) {
      let timer = null;
      let idx = 0;
      let groupIndex = 0;

      // await new Promise(async (resolve) => {
      async function typeGroup(num, delay) {
        if (groupIndex < item.length / num) {
          const group = item.slice(idx, idx + num);
          // typingElement.textContent += group;
          if (isLoading) {
            setChatList((prev) => {
              // 1、 解析出content数据结构
              let lastItem = prev[prev.length - 1];
              let lastRes = JSON.parse(lastItem?.resContent || "{}");

              // 2、 添加上新文本
              lastRes.content[index].data += group;
              // console.log("🚀 ~ setChatList ~ lastContent:", lastContent);

              prev[prev.length - 1] = {
                ...lastItem,
                resContent: JSON.stringify(lastRes),
              };
              return [...prev];
            });
            idx += num;
            groupIndex++;
            setTimeout(() => typeGroup(3, 5), delay);
          } else {
          }
        } else {
          allPart -= 1;
          if (allPart === 0) {
            store.dispatch({
              type: "sysStatusInfo",
              messageInfo: {
                msgId: "",
                isSending: false,
              },
            });
          }
        }
      }
      await typeGroup(3, 5);
      // }); // 调整时间间隔
      return true;
    }

    for (let i = 0; i < streamContent.content.length; i++) {
      let item = streamContent.content[i];
      // console.log("🚀 ~ onMessage ~ item:", item);
      if (
        ![...Object.keys(plugins), ...noOneByOneGenWordMap].includes(
          item?.return_type
        ) &&
        typeof item.data == "string"
      ) {
        streamText(item.data, i);
      }
    }
  };

  // 初始化页面后，重置状态
  useEffect(() => {
    openNewTopic();
  }, []);
  useEffect(() => {
    // console.log('wsData, readyState,', wsData)

    // 接受到socket数据， 进行业务逻辑处理
    if (Object.keys(wsData).length !== 0) {
      // console.log('onmessage', wsData)
      onMessage(wsData);
    }
  }, [wsData]);

  let commandsMap = {
    打开设备列表: goHome,
    返回首页: goHome,
    返回配方列表: goDeviceList,
    开始实验: () => {
      eventBus.$emit("controlZZTB", "开始实验");
    },
    继续实验: () => {
      eventBus.$emit("controlZZTB", "继续实验");
    },
    暂停实验: () => {
      eventBus.$emit("controlZZTB", "暂停实验");
    },
    结束实验: () => {
      eventBus.$emit("controlZZTB", "结束实验");
    },
    进入实验室模式: () => {
      eventBus.$emit("roomModeZZTB", "room");
    },
    返回实验界面: () => {
      eventBus.$emit("roomModeZZTB", "single");
    },
    帮我把专利发送到邮箱: () => {},
    帮我把实验报告发送到邮箱: () => {
      sendmail({
        // content: "",
        fileName:
          "FromDoi_Synthesis of photocatalysts and photocatalytic reduction of CO2 .pdf",
        fileUrl:
          "https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/ffv3/ai-download-files/FromDoi_Synthesis%20of%20photocatalysts%20and%20photocatalytic%20reduction%20of%20CO2%20.pdf",
        subject: "实验报告",
        to: "lijiagen@finefanta.com ",
      }).then((res) => {
        console.log("🚀 ~ sendmail ~ res:", res);
      });
    },
  };
  // 处理实验指令
  const doCommand = (text) => {
    let commandKeys = Object.keys(commandsMap);
    if (commandKeys.includes(text)) {
      console.log(
        "🚀 ~ doCommand ~ text:",
        text,
        commandsMap,
        commandsMap[text]
      );
      commandsMap[text] && commandsMap[text]();
    } else {
      // message.error("指令不存在");
    }
  };

  const talk2AI = (msg, ext) => {
    let params = {
      id: chatInfo?.id || "",
      // isAI: !commandKeys.includes(msg.content),
      // ext: { recordId: chatInfo?.id || "" },
      isAI: true,
      resContent: "",
      userId: userInfo?.id,
      ext,
      orgNumber: userInfo?.orgNumber,
    };
    params.contentDo = msg;

    sendMessage(params);

    // 消息发送后，处理收到消息前的模拟消息。
    setChatList((prev) => {
      // 1、 解析出content数据结构
      prev.push({
        id: userInfo?.id,
        resContent: JSON.stringify({
          content: [{ data: "", return_type: "chat" }],
        }),
        reqContent: JSON.stringify({ ...msg }),
      });
      return [...prev];
    });

    store.dispatch({
      type: "sysStatusInfo",
      messageInfo: {
        msgId: userInfo?.id,
        isSending: true,
      },
    });
  };
  // 用permise 实现异步延时
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  let msgObserver = useCallback(
    (messages) => {
      // console.log("🚀 ~ msgObserver ~ message:", messages, chatList);
      // let List = chatList;
      let newMessages = false;

      // if (message.content.includes("钙钛矿")) {
      //   message.content = "钙钛矿";
      // }
      switch (messages.content) {
        case "当前实验进度":
          // console.log("当前实验进度");
          let res = {
            content: [
              {
                return_type: "chat",
                data: `当前实验进度为39%，目前已完成所有移液动作，试管区K1号瓶子以1：11的比例装入水和四氯化钛，试管区2号瓶子以1：9的比例装入水和NH3·H2O，试管区K3号瓶子装入3.2ml的水和乙醇混合液体。正在进行加热反应。\n
原料统计：四氯化钛3.4ml，NbCl5原料1.2ml，水0.8ml\n
用电统计：目前已使用12kw/h，预计完成实验需耗电20kw/h`,
              },
            ],
          };
          newMessages = {
            aiDialogueId: "18471112675sd285524349",
            id: nanoid(),
            reqContent:
              '{"content":"' +
              messages.content +
              '","role":"user","contentType":0}',
            resContent: JSON.stringify(res),
          };
          break;

        default:
          break;
      }

      sleep(2000).then(() => {
        // console.log("🚀 ~ sleep ~ List:", List);
        if (newMessages) {
          setChatList((predata) => {
            let oldList = [...predata];
            return [...oldList, newMessages];
          });
        }
      });
      // console.log("🚀 ~ ChatAIContainer ~ chatList:", chatList, newMessages);
      return newMessages;
    },
    [chatList, setChatList]
  );

  // 发送语音  contentType: 0, // 输入类型， 0：文本 1：文件 2：图片 3：语音 4：视频
  const sendMessageFn = useCallback(
    (messages, ext) => {
      // console.log("sendText------", messages, chatList, aiStatus);
      if (aiStatus?.messageInfo?.isSending) {
        return message.warning("请先等本次内容输出完成后再点击！");
      }

      let sortWord = msgObserver(messages);
      console.log("🚀 ~ ChatAIContainer ~ sortWord:", sortWord);
      if (sortWord) return;
      // let lastData = [...chatList, message];
      // // 添加新的用户User的对话记录
      // setChatList(lastData);
      // 处理指令
      doCommand(messages?.content);
      // 与AI对话
      talk2AI(messages, ext);
    },
    [chatList, aiStatus]
  );

  // 开始自动化生成
  const [isAutoComplete, setIsAutoComplete] = useState(false);

  const autoSendMessage = useCallback(
    (content) => {
      sendMessageFn(content);
    },
    [sendMessageFn]
  );
  const startAutoComplete = () => {
    if (isAutoComplete) return;
    stepCount = 0;

    let steps = ["开始自动运行流程", "idea:异质结光催化剂", "生成配方"];
    timer = setInterval(() => {
      if (stepCount < steps.length) {
        autoSendMessage({ content: steps[stepCount] });
        stepCount++;
      } else {
        stepCount = 0;
        stopAutoComplete();
      }
    }, 5000);
  };
  // 停止自动化生成
  const stopAutoComplete = () => {
    clearInterval(timer);
  };

  // 维护AI聊天的状态：是否录音，是否发送, 是否自动完成
  useEffect(() => {
    // if (aiStatus?.autoComplete) {
    //   setIsAutoComplete(true);
    //   startAutoComplete();
    // } else {
    //   setIsAutoComplete(false);
    //   stopAutoComplete();
    // }
    eventBus.$on("AIStatus", (obj) => {
      store.dispatch({
        type: "sysStatusInfo",
        ...obj,
      });
    });
    // console.log("🚀 ~ evAIStatusj少时诵诗书:", eventBus.eventList);
    return () => {
      eventBus.$off("AIStatus");
    };
  }, [aiStatus, chatList]);

  // const [streamText, setStreamText] = useState("");
  // useEffect(() => {
  //   const socket = new WebSocket("ws://localhost:8080");
  //   // console.log("🚀 ~ useEffect ~ socket:", socket);
  //   socket.onmessage = (event) => {
  //     // console.log("🚀 ~ event:", event);
  //     setStreamText((predata) => {
  //       let predatas = predata;
  //       return predatas + event.data;
  //     });
  //   };
  // }, []);
  // 接收系统 生成的消息
  useEffect(() => {
    eventBus.$on("genChatItem", (text) => {
      // console.log("🚀 ~ eventBus.$on ~ obj:", obj);
      if (text === "当前实验已结束，可前往查看实验报告。") {
        let msgData = {
          return_type: "gaiFinish",
          data: text,
          role: "copilot",
        };
        let content = {
          content: [msgData],
        };

        let newChat = {
          aiDialogueId: "18471112627523855244922",
          id: nanoid(),
          reqContent: '{"content":""}',
          resContent: JSON.stringify(content),
        };
        // console.log("🚀 ~ eventBus.$on ~ newChat:", newChat);
        setChatList([...chatList, newChat]);
      } else {
        let msgData = {
          return_type: "chat",
          data: text,
        };
        let content = {
          content: [msgData],
        };

        let newChat = {
          aiDialogueId: "18471112627523855244922",
          id: nanoid(),
          reqContent: '{"content":"{}"}',
          resContent: JSON.stringify(content),
        };
        // console.log("🚀 ~ eventBus.$on ~ newChat:", newChat);
        setChatList([...chatList, newChat]);
      }
    });

    return () => {
      eventBus.$off("genChatItem");
    };
  }, [chatList]);

  // 显示历史记录
  const showHistory = (data) => {
    if (data?.id) {
      setChatInfo({ id: data?.id });
      aiDialogue({ id: data?.id }).then((res) => {
        if (res.code == "0") {
          setChatList(res.data?.aiDialogueDetails);
        }
        // console.log("🚀 ~ aiDialogue ~ res:", res);
      });
    }
    // eventBus.$emit(
    //   "genChatItem",
    //   `钙钛矿innovation配方使用了四氯化钛、NH3·H2O、NbCl5、水和乙醇原料。`
    // );
    let nav = aiStatus?.nav === "history" ? "chat" : "history";
    if (data?.id) {
      nav = "chat";
    }
    // console.log("🚀 ~ showHistory ~ nav:", aiStatus, data, nav);
    store.dispatch({
      type: "sysStatusInfo",
      nav: nav,
    });
  };

  // 打开新话题
  const openNewTopic = () => {
    store.dispatch({
      type: "sysStatusInfo",
      nav: "chat",
      isRecording: false,
      messageInfo: {
        msgId: "",
        isSending: false,
      },
      autoComplete: false,
    });
    setChatInfo({});
    setChatList([]);
  };
  // 切换话题
  const changeTopic = (info) => {
    // console.log("🚀 ~ changeTopic ~ f:", info);
    // setChatInfo(info);
    openNewTopic();
  };
  const [basisData, setBasisData] = useState({});
  useEffect(() => {
    getSysBasisData().then((res) => {
      // console.log("🚀 ~ getSysBasisData ~ res:", res)
      if (res.code == 0) {
        document.title = res?.data.sysName || t("幻爽实验控制系统");
        setBasisData(res.data);
      }
    });
  }, []);
  // const diff = dmp.diff_main("dogs bark", "cats bark");
  return (
    <div className="ChatAIContainer">
      <HeaderField
        basisData={basisData}
        setShowAI={setShowAI}
        showHistory={showHistory}
        openNewTopic={openNewTopic}
      ></HeaderField>
      {aiStatus.nav === "history" && (
        <div className="historyField">
          <HistoryView showHistory={showHistory}></HistoryView>
        </div>
      )}
      <div className="layoutCenter">
        <Markdown />
        <ChatField
          basisData={basisData}
          chatList={chatList}
          sendMessage={sendMessageFn}
          aiStatus={aiStatus}
          showHistory={showHistory}
        ></ChatField>

        <InputField
          scrollToAnchor={scrollToAnchor}
          aiStatus={aiStatus}
          sendMessage={sendMessageFn}
          changeTopic={changeTopic}
          openNewTopic={openNewTopic}
        ></InputField>
      </div>
    </div>
  );
}
