export const PC = (el) => {
    return [
        {
            title: '光热模块',
            blockName: 'A',
            description: <div >共有8个瓶子，可对反应容器进行磁搅拌、加热、光源和气体操作。</div >,
            target: () => el[0],
            placement: 'top',
            step: 4,
        },
        {
            title: '光电模块',
            blockName: 'B',
            description: <div >共有8个瓶子，可对反应容器进行电磁铁、加热、光源和通气操作。</div >,
            target: () => el[0],
            placement: 'top',
            step: 5,
        },
        {
            title: '清洗模块',
            blockName: 'C1',
            description: <div >清洗模块分为两部分，上部分为清洗模组，可配置清洗针头动作；下部分为原料瓶，可根据需要添加原料。</div >,
            target: () => el[0],
            placement: 'top',
            step: 6,
        },
        {
            title: '处理模块',
            blockName: 'D',
            description: <div >清洗模块分为两部分，上部分18个瓶子为原料，可根据需要添加原料；下部分为处理区，可存放其他区域的反应样品。</div >,
            target: () => el[0],
            placement: 'top',
            step: 7,
        },
        {
            title: '96孔光催化模块',
            blockName: 'E',
            description: <div >共三个板块，每个板块有96个孔位，可配置加热、制冷和光源操作。</div >,
            target: () => el[0],
            placement: 'top',
            step: 8,
        },
        {
            title: '原料模块',
            blockName: 'F',
            description: <div >用于放置实验所需的原料。</div >,
            target: () => el[0],
            placement: 'top',
            step: 9,
        }
    ];
}

export const PC1= (el) => {
    return [
        {},
        {
            title: '添加原料',
            blockName: 'C1',
            description: <div >在清洗模块原料区单击选择任意瓶子添加实验原料。</div >,
            target: () => el[0],
            prevButtonProps: { style: { display: 'none' } },
            placement: 'top',
            step: 1,
        }
    ];
}

export const PC2 = (el) => {
    return [
        {},
        {
            title: '填写原料和容量',
            description: <div >选择实验的原料并填写适当的容量。填写完成后请点击【提交】按钮。</div >,
            target: () => el[0],
            prevButtonProps: { style: { display: 'none' } },
            placement: 'right',
            step: 2,
        }
    ];
}

export const PC3 = (el) => {
    return [
        {},
        {
            title: '点击【下一步】',
            description: <div >实验原料添加完成后，点击【下一步】进入流程的配置。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'top',
            step: 3,
        }
    ];
}

export const PC4 = (el) => {
    return [
        {},
        {
            title: '移液',
            blockName: 'C1',
            description: <div >红色代表含有液体的容器，仅红色容器可单击进入移液动作的配置。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'top',
            step: 4,
        }
    ];
}

export const PC5 = (el) => {
    return [
        {},
        {
            title: '填写移液信息',
            description: <div >可选择液体移入的位置，即区块类型和点位序号；同时可根据需求填写注液量和注液速度。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'right',
            step: 5,
        }
    ];
}

export const PC6 = (el) => {
    return [
        {
            title: '点击光热模块',
            blockName: 'A',
            description: <div >鼠标移入光热模块至绿色时，单击可配置光热模块的动作及参数。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}

export const PC7 = (el) => {
    return [
        {
            title: '配置反应动作和参数',
            description: <div >整个模块可配置磁搅拌、加热的动作；每个瓶子可独立配置光源和气体的操作。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'right',
            step: 1,
        },
    ];
}
export const PC8 = (el) => {
    return [
        {
            title: '点击光电模块',
            blockName: 'B',
            description: <div >鼠标移入光电模块至绿色时，单击可配置光电模块的动作及参数。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}

export const PC9 = (el) => {
    return [
        {
            title: '配置反应动作和参数',
            description: <div >每两个瓶子为一个通道，每个通道可配置电磁铁和加热的动作；每个瓶子可独立配置光源和通气的操作。</div >,
            target: () => el[0],
            placement: 'right',
            step: 1,
        },
    ];
}

export const PC10 = (el) => {
    return [
        {
            title: '点击清洗模块',
            blockName: 'C1',
            description: <div >鼠标移入清洗模块至绿色时，单击可配置清洗模块的动作及参数。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}

export const PC11 = (el) => {
    return [
        {
            title: '配置反应动作和参数',
            description: <div >清洗模块可选择清洗的针头和清洗的开关状态。</div >,
            target: () => el[0],
            placement: 'right',
            step: 1,
        },
    ];
}

export const PC12 = (el) => {
    return [
        {
            title: '点击96孔光催化模块',
            blockName: 'E',
            description: <div >鼠标移入96孔光催化模块至绿色时，单击可配置96孔光催化模块的动作及参数。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}

export const PC13 = (el) => {
    return [
        {
            title: '配置反应动作和参数',
            description: <div >96孔光催化模块可配置制冷，每个区块可配置光源和加热动作。</div >,
            target: () => el[0],
            placement: 'right',
            step: 1,
        },
    ];
}

export const PC14 = (el) => {
    return [
        {},
        {
            title: '流程展示',
            description: <div >配置好的动作将按照顺序展示在左侧。</div >,
            target: () => el[0],
            placement: 'left',
            step: 1,
        },
        {
            title: '编辑分组',
            description: <div >单击分组的编辑按钮，可修改分组的信息。</div >,
            target: () => el[1],
            placement: 'left',
            step: 2,
        },
    ];
}

export const PC15 = (el) => {
    return [
        {
            title: '编辑分组',
            description: <div >可修改分许的名称和循环次数。循环次数代表该分组下所有动作的循环次数，可对一组动作进行重复性实验。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}

export const PC16 = (el) => {
    return [
        {},
        {
            title: '点击【模拟】',
            description: <div >实验动作全部添加完成后，点击【模拟】进入实验模拟。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        }
    ];
}

export const PC17 = (el) => {
    return [
        {},
        {
            title: '点击【上一步】',
            description: <div >点击【上一步】可回到流程配置修改内容。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
        {
            title: '倍速',
            description: <div >模拟支持1倍速、8倍速、16倍速和32倍速。</div >,
            target: () => el[1],
            placement: 'top',
            step: 2,
        },
        {
            title: '点击【开始模拟】',
            description: <div >点击开始模拟代表模拟开始，即模拟实际机器的运行步骤。</div >,
            target: () => el[2],
            placement: 'top',
            step: 3,
        }
    ];
}

export const PC18 = (el) => {
    return [
        {
            title: '点击【开始实验】',
            description: <div >实验模拟结束，可点击【开始实验】进入到实验的开始页面。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}
export const PC19 = (el) => {
    return [
        {
            title: '点击【配方列表】',
            description: <div >实验模拟结束，也可点击【配方列表】回到配方的卡片页，运行实际设备。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}
export const PC20= (el) => {
    return [
        {
            title: '开始实验',
            description: <div >每个配方会生成一个卡片，点击卡片上的【开始】代表会实际运行实验室设备。<span style={ { fontWeight: 'bold' } }>引导结束了，可以去创建配方开始实验了！</span></div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}
export const PC21 = (el) => {
    return [
        {
            title: '暂停和结束',
            description: <div >暂停代表暂停模拟，结束代表结束本次模拟。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}
