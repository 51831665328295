import React, { useState, useEffect, useId } from "react";
import Vditor from "vditor";
import "vditor/dist/index.css";
export default function MarkdownEditor(props) {
  const [vd, setVd] = useState();
  const id = useId();
  useEffect(() => {
    // console.log(props.content);

    const vditor = new Vditor(id, {
      mode: "wysiwyg",
      after: () => {
        vditor.setValue(props.content);

        setVd(vditor);
      },
    });
    // Clear the effect
    return () => {
      vd?.destroy();
      props.setEditContent(props.sindex, vditor.getValue());
      setVd(undefined);
    };
  }, [props.content]);
  return <div id={id}></div>;
}
