import React, { useState, useEffect } from "react";
import { Button, Flex, message } from "antd";
import { nanoid } from "nanoid";

import { aiDialogueDownload } from "../../../utils/ai-pl-api";
import styles from "./index.scss";
export default function OperaButtons({
  sitem,
  msgContent,
  currentId,
  sendMessage,
  sindex,
  isEdit,
  setIsEdit,
  currentMsg,
  aiStatus,
}) {
  const returnTypeMap = {
    //普通对话
    chart: [],
    //论文信息
    info: [],
    //专利
    patent: [],
    //论文信息中的实验
    "user-idea": ["提交"],
    //方向总结
    summary: ["思路创新", "论文写作", "专利写作", "生成配方"],
    //方向总结中单独的idea
    "innovation-idea": ["创新方法"],
    //方法信息
    method: ["修改实验方法", "论文写作", "专利写作", "生成配方"],
    //文章信息
    "old-article": ["编辑", "下载", "更新表征结果数据", "添加引用"],
    //润色后的文章信息
    "new-article": ["编辑", "下载", "更新表征结果数据", "添加引用", "更新论文"],
    new_article: ["编辑"],
    whole_new_article: ["下载", "更新表征结果数据", "添加引用", "更新论文"],
    old_article: ["编辑"],
    whole_article: ["下载", "更新表征结果数据", "添加引用", "更新论文"],
    reference: ["编辑"],
    //实验表征描述
    result_data: ["更新表征结果数据"],
  };
  let primaryGhost = { type: "primary", danger: false, ghost: true };
  const [btnArr, setBtnArr] = useState([]);
  const [articleStr, setArticleStr] = useState("");
  // const [isEdit, setIsEdit] = useState(false);

  const btnTypeMap = {
    思路创新: primaryGhost,
    修改实验方法: primaryGhost,
    编辑: primaryGhost,
    更新表征结果数据: primaryGhost,
  };

  const btnToContentType = {
    思路创新: "innovation-idea",
    提交: "summary",
    论文写作: "old-article",
    专利写作: "patent",
    生成配方: "ff",
    创新方法: "method",
    添加引用: "reference",
    更新表征结果数据: "result_data",
    更新论文: "new_article",
  };

  useEffect(() => {
    // tempMsg[index].data
    if (sitem.return_type) {
      setBtnArr(returnTypeMap[sitem.return_type] || []);
      let editArr = [...isEdit];
      editArr[sindex] = false;
      setIsEdit(editArr);
    }
  }, [sitem]);

  useEffect(() => {
    let str = "";
    currentMsg.forEach((element) => {
      str += element.data;
    });
    setArticleStr(str);
  }, [currentMsg]);

  const handleSendMsg = (item) => {
    let deleJint;
    let title;

    switch (sitem.return_type) {
      case "innovation-idea":
        deleJint = sitem.data.split("#");
        break;
      case "user-idea":
        deleJint = sitem.data.split("###");
        break;

      default:
        deleJint = sitem.data.split("#");
        break;
    }

    if (deleJint[1]) {
      title = deleJint[1].split("\n")[0];
    } else {
      title = sitem.data;
    }

    if (
      sitem.return_type == "whole_article" ||
      sitem.return_type == "whole_new_article"
    ) {
      title = articleStr;
      // console.log(currentMsg);

      sendMessage(
        {
          content: `${item}`,
          role: "user",
          contentType: 0,
          actionType: btnToContentType[item],
        },
        { article: currentMsg }
      );
    } else {
      sendMessage({
        content: `${item}：${title}`,
        role: "user",
        contentType: 0,
        actionType: btnToContentType[item],
      });
    }
  };

  const handleClick = (item) => {
    // console.log(aiStatus);
    if (aiStatus.messageInfo.isSending) {
      message.warning("请先等本次内容输出完成后再点击！");
      return;
    }
    let editArr = [...isEdit];

    switch (item) {
      case "提交":
        if (sitem.return_type == "user-idea") {
          handleSendMsg(item);
        } else {
          editArr[sindex] = false;
          setIsEdit(editArr);
        }
        // console.log(sindex);

        break;
      case "思路创新":
      // break;
      case "论文写作":
      // break;
      case "专利写作":
      // break;
      case "生成配方":
      // break;
      case "创新方法":
      // break;
      case "更新表征结果数据":
      // break;
      case "更新论文":
      // break;
      case "添加引用":
        handleSendMsg(item);
        break;
      case "修改实验方法":
      case "编辑":
        editArr[sindex] = true;
        // console.log(editArr);

        setIsEdit(editArr);
        break;
      case "提交":
        editArr[sindex] = false;
        setIsEdit(editArr);
        break;
      case "下载":
        aiDialogueDownload(currentId).then((res) => {
          let url = window.URL.createObjectURL(res); // 创建一个临时的url指向blob对象
          // 创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
          let a = document.createElement("a");
          a.href = url;
          a.download = `文章.doc`;
          a.click();
          // 释放这个临时的对象url
          window.URL.revokeObjectURL(url);
        });
        break;

      default:
        break;
    }
  };

  const btnText = (item) => {
    return item == "修改实验方法" || item == "编辑"
      ? isEdit[sindex]
        ? "提交"
        : item
      : item;
  };

  return (
    btnArr.length !== 0 && (
      <div className={styles.operaButtonsMain}>
        {/* <Flex gap="small" wrap> */}
        {btnArr?.map((item) => (
          <Button
            key={nanoid()}
            type={
              btnTypeMap[btnText(item)]?.type
                ? btnTypeMap[btnText(item)].type
                : "primary"
            }
            danger={btnTypeMap[btnText(item)]?.danger}
            ghost={btnTypeMap[btnText(item)]?.ghost}
            className={styles.btn}
            onClick={() => {
              console.log("🚀 ~ item:", item);
              handleClick(btnText(item));
            }}
          >
            {btnText(item)}
          </Button>
        ))}
        {/* </Flex> */}
      </div>
    )
  );
}
