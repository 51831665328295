// 骨架屏组件
import { Skeleton, Row, Col } from "antd";
export default function SkeletonDom() {
    return (
        <div className='rootSkeleton' >
            <Row style={{ width: '76%', height: '76%' }}>
                <Col span={3}>
                    <Skeleton
                        avatar
                        active
                        paragraph={{
                            rows: 10,
                            // width: '200px'
                        }}
                    />
                </Col>
                <Col span={20} offset={1}>
                    <Skeleton
                        active
                        paragraph={{
                            rows: 10,
                            width: '100%'
                        }}
                    />
                </Col>
            </Row>

        </div>
    )
}
