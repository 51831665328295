import { useState, useEffect, useContext, Fragment } from "react";
import { Button, Row, Col, message } from "antd";
import { nanoid } from "nanoid";
import { listFindItem } from "~/utils/jstools";
import {
  PluginContext,
  CommandContext,
  GlobalDataContext,
} from "../../../utils/reactContext.js";
import "../publicStyle.scss";

import eventBus from "~/utils/hooks/eventBus";

export default function MoveLiquid({ nodeData, content }) {
  // const [message, setMessage] = useState("");
  const valueDict = "";
  const globalData = useContext(GlobalDataContext);
  const [actionJsonData, setActionJsonData] = useState({});
  useEffect(() => {
    // console.log("🚀 ~ globalData:", globalData, content);
    setActionJsonData(JSON.parse(content || "{}"));
  }, [content]);
  let data1 = {
    functionCode: "startNitrogenBlowB",
    taskProcessId: "1847245652891668482",
    paramArr: [
      {
        blockType: "A",
        nitrogenBlowBSpeed: "2",
        startNitrogenBlowBExecuteTime: "44",
      },
    ],
  };
  let data = {
    functionCode: "moveLiquidB",
    paramArr: [
      { blockType: "P", num: 1 },
      {
        blockType: "K",
        num: "2",
        commonInjectBBlockType: "K",
        injectionVolume: "1",
        liquidSpeed: "1",
      },
    ],
  };
  // useEffect(() => {
  //   console.log("🚀 ~ nodeData:", globalData);
  //   if (globalData?.allTaskTypeListZZT2) {
  //     setFunctionName(
  //       globalData.allTaskTypeListZZT2[data1.functionCode]?.functionName || ""
  //     );
  //   }
  // }, [globalData]);
  if (!globalData?.allTaskTypeListZZT2 || !globalData?.blockTypeListZZT2)
    return <span className="textVal">请进入配方配置页面，进行此操作。</span>;
  const applyAction = () => {
    if (
      eventBus.eventList["AISubmitActionZZT2"] &&
      eventBus.eventList["AISubmitActionZZT2"].length > 0
    ) {
      eventBus.$emit("AISubmitActionZZT2", actionJsonData);
    } else {
      message.error("该操作执行失败");
    }
    console.log("🚀 ~ applyAction ~ data:", eventBus.eventList);
  };
  return (
    <div className="aiActionView">
      <div className="treeColBox">
        <div className="taskInfo">
          <ul className="paramList">
            <Row>
              <Col span={23}>
                动作：
                <span className="textVal">开始氮吹</span>
              </Col>
              {actionJsonData?.paramArr &&
                actionJsonData?.paramArr.map((item, friIndex) => {
                  // console.log("🚀 ~ data?.paramArr.map ~ item:", item);
                  // if (friIndex == 0) {
                  //   return (
                  //     <Col span={23}>
                  //       动作：
                  //       <span className="textVal">移液</span>
                  //     </Col>
                  //   );
                  // }

                  return (
                    <Fragment>
                      {friIndex == 0 && (
                        <Col span={23}>
                          动作： <span className="textVal">吸液</span>
                        </Col>
                      )}
                      {friIndex > 0 && (
                        <Col span={23}>
                          动作： <span className="textVal">注液</span>
                        </Col>
                      )}
                      {Object.keys(item).map((param, index) => {
                        // console.log(
                        //   "🚀 ~ {Object.keys ~ param:",
                        //   param,
                        //   globalData?.allTaskTypeParamListZZT2[param]
                        // );
                        let paramName = "";
                        let paramValue = "";
                        let paramUnit = "";
                        if (globalData?.allTaskTypeParamListZZT2[param]) {
                          paramName =
                            globalData?.allTaskTypeParamListZZT2[param]
                              ?.frontShow;
                          paramValue = item[param];

                          paramUnit =
                            globalData?.allTaskTypeParamListZZT2[param]
                              ?.frontShow;
                        }
                        if (param == "blockType" && friIndex > 0) return null;
                        return (
                          <Col span={12} key={nanoid()}>
                            <li style={{ display: "flex" }}>
                              <span className="frontShow">{paramName}:</span>
                              <span className="textVal">{paramValue}</span>
                              {paramUnit && <span>{paramUnit}</span>}
                            </li>
                          </Col>
                        );
                      })}
                    </Fragment>
                  );
                })}
            </Row>
          </ul>
        </div>
      </div>
      <div className="posBtn">
        <Button type="primary" onClick={applyAction}>
          应用
        </Button>
      </div>
    </div>
  );
}
