import { useState, useEffect, Fragment, useContext } from "react";
import { Button, Input, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import eventBus from "~/utils/hooks/eventBus";
import { nanoid } from "nanoid";
import Markdown from "./markdown";
import OperaButtons from "./operaButtons";
import "./index.scss";
import MarkdownEditor from "./markdownEditor";

import { PluginContext } from "~/components/AIPL/utils/reactContext.js";

import { saveDetail } from "../../utils/ai-pl-api";
import GeneFormulaFile from "./geneFormulaFile";
export default function IdeaField({
  msg,
  sId,
  wholeItem,
  sendMessage,
  aiStatus,
  showHistory,
}) {
  const userInfo = JSON.parse(window.localStorage.getItem("userInfo") || "{}");

  // console.log("🚀 ~ IdeaField ~ msg:", msg);
  const [isEdit, setIsEdit] = useState([]);
  const [currentMsg, setCurrentMsg] = useState(msg.content);
  const Plugins = useContext(PluginContext);
  const [isClickEdit, setIsClickEdit] = useState(false);
  const [formulaFiles, setFormulaFiles] = useState();

  useEffect(() => {
    isClickEdit || setCurrentMsg(msg.content);
  }, [msg.content]);

  // const [resContent, setResContent] = useState(msg?.content);
  const setEditContent = (index, content) => {
    let resC = { content: [] };
    let tempMsg = [...currentMsg];
    tempMsg[index].data = content;
    tempMsg.forEach((item) => {
      resC.content.push({ ...item });
    });
    let resJson = JSON.stringify(resC);
    const data = { id: wholeItem.id, resContent: resJson };
    setIsClickEdit(true);
    // console.log(wholeItem);
    saveDetail(data).then((res) => {
      if (res.code == 0) {
        // console.log("📕--------------", res);

        setCurrentMsg(tempMsg);
      } else {
        message.error(res.msg);
      }
    });

    // console.log(content);
  };
  return (
    <div className="chatFlowItem robot" id={sId}>
      {/* {compareTexts()} */}
      <div className="avatar">
        {aiStatus.messageInfo.isSending &&
          aiStatus.messageInfo.msgId === sId && (
            <Spin
              className="loadingAvatar"
              indicator={
                <LoadingOutlined
                  style={{ width: "100%", height: "100%" }}
                  spin
                />
              }
            />
          )}
      </div>
      <div className="chatContent">
        {currentMsg &&
          currentMsg?.map((sitem, sindex) => {
            const Component = Plugins[sitem?.return_type] || "";
            // console.log(
            //   "🚀 ~ {itemResParse.map ~ item:",
            //   sitem,
            //   Plugins,
            //   Component
            // );
            return (
              <div
                key={sId + sindex + ""}
                className={
                  sitem.return_type == "whole_article" ||
                  sitem.return_type == "whole_new_article"
                    ? "chatText chatTextNobg"
                    : sitem.return_type == "new_article"
                    ? "chatText newArticle"
                    : "chatText"
                }
              >
                {/* 处理 动作的组件专用 */}
                {Component !== "" && (
                  <Component aiStatus={aiStatus} content={sitem.data} />
                )}

                {/* 编辑内容 */}
                {isEdit[sindex] && Component === "" && (
                  <MarkdownEditor
                    content={sitem.data}
                    aiStatus={aiStatus}
                    setEditContent={setEditContent}
                    sindex={sindex}
                  />
                )}
                {/* 生成配方 */}
                {sitem.return_type == "ff" && (
                  <GeneFormulaFile formulaFiles={sitem.data} />
                )}
                {/* 处理聊天对话的内容 */}
                {!isEdit[sindex] &&
                  sitem.return_type !== "ff" &&
                  Component === "" && <Markdown content={sitem.data} />}

                {/* 处理各对话场景下的不同的功能按钮 */}
                {(sitem?.data ||
                  sitem.return_type == "whole_article" ||
                  sitem.return_type == "whole_new_article") &&
                  Component === "" && (
                    <OperaButtons
                      sindex={sindex}
                      isEdit={isEdit}
                      aiStatus={aiStatus}
                      setIsEdit={setIsEdit}
                      sitem={sitem}
                      currentId={wholeItem.id}
                      sendMessage={sendMessage}
                      currentMsg={currentMsg}
                    />
                  )}
                {/* <Button>创新方法</Button> */}
              </div>
            );
          })}

        {msg?.url && (
          <a href={msg?.url} target="_black" className="link">
            {msg?.url}
          </a>
        )}
        {/* <div className="chatAction">
          <Space>
            <Button  type="primary">
              编辑
            </Button>
            <Button  type="primary">
              下载
            </Button>
          </Space>
        </div> */}
      </div>

      {aiStatus.messageInfo.isSending &&
        aiStatus.messageInfo.msgId === sId &&
        userInfo?.id !== sId && (
          <div
            style={{
              padding: "10px 52px",
              display: "inline-block",
              width: "100%",
            }}
          >
            <Button
              type="primary"
              size="small"
              onClick={() => {
                eventBus.$emit("AIStatus", {
                  messageInfo: {
                    msgId: "",
                    isSending: false,
                  },
                });
              }}
            >
              停止生成
            </Button>
          </div>
        )}
    </div>
  );
}
