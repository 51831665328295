import * as THREE from 'three'
// import { coordMap } from './hardwareConfig';
import { moduleObjs, channelObjs, shelf, staticModuleObjs, xAxis, yAxis } from '../photocatalysis'
import { moduleAxisMap } from './hardWareConfig';
import { injectBot, reaction, peristalticBot } from '../../quantumDot';

const opacityMaterial = new THREE.MeshBasicMaterial({
    color: 0x00ff00,     // 颜色
    transparent: true,    // 是否开启使用透明度
    opacity: 0.3,        // 透明度
    depthWrite: false,    // 关闭深度写入 透视效果
    side: THREE.DoubleSide, // 双面显示
});

const noModules = ['bot', 'n2', 'tip', '100ml', 'cap']

// /**
//  *
//  * @param {*} module :模块类型
//  * @param {*} col :行y
//  * @param {*} row :列x
//  */
// const coordToNum = (module, row, col) => {
//     let index
//     if (module === 'G') {
//         index = col < 7 ? (row - 1) * coordMap['G1'] + col : col > 7 ? (row - 1) * coordMap['G3'] + col : 18 + col
//     }
//     index = (row - 1) * coordMap[module] + col

//     return index
// }


// const numToCoord = (moduleName, number) => {
//     let returnObj = { col: 0, row: 0 }
//     if (moduleName === 'G') {
//         if (number < 19) {
//             returnObj.row = Math.ceil(number / coordMap['G1'])
//             returnObj.col = number % coordMap['G1'] || coordMap['G1']
//         } else if (number < 21) {
//             returnObj.row = Math.ceil((number - 18) / coordMap['G2']) + 6
//             returnObj.col = (number - 18) % coordMap['G2'] || coordMap['G2']
//         } else if (number < 44) {
//             // console.log(moduleName, number, coordMap['G3']);
//             returnObj.row = Math.ceil((number - 20) / coordMap['G3']) + 7
//             returnObj.col = (number - 20) % coordMap['G3'] || coordMap['G3']
//         }
//     } else {
//         returnObj.row = Math.ceil(number / coordMap[moduleName])
//         returnObj.col = number % (coordMap[moduleName]) || coordMap[moduleName]
//     }
//     return returnObj
// }

/**
 *
 * @param {String} equipmentType :设备类型
 * @param {String} module :模块类型
 * @param {Number} number :瓶子编号
 * @param {rgb} color :颜色
 */
const setColor = (config) => {
    try {
        const { equipmentType, number, color } = config
        let changeObj
        let isObjArr = false
        if (equipmentType == 5) {
            let module = config.module == 'C1' ? 'C' : config.module
            if (number && moduleObjs) {
                if (!isNaN(parseFloat(number)) && isFinite(number) && moduleObjs[module][number - 1]) {
                    changeObj = moduleObjs[module][number - 1]
                } else {
                    let num = number.substr(-1) * 1
                    if (module == 'D2') {
                        num = num - 3
                    }

                    changeObj = channelObjs[module][num - 1]
                }
            } else {
                changeObj = shelf[module]
            }
            // console.log(moduleObjs);
        } else if (equipmentType == 5) {
            if (module == 'A1') {
                changeObj = injectBot[number - 1]
            } else if (module == 'B1') {
                changeObj = peristalticBot[number - 1]
            } else if (module == 'C') {
                changeObj = reaction
                isObjArr = true
            }

        }

        if (changeObj) {
            if (isObjArr) {
                changeObj.forEach(item => handleColor(item, color))
            } else {
                handleColor(changeObj, color)
            }
        }
    } catch (error) {
        console.log(error)
    }
}

const handleColor = (changeObj, color) => {
    if (!changeObj) {
        return
    }
    if (color == 'origin') {

        changeObj.traverse(child =>
            child?.geometry && child?.originMaterial && (child.material = child?.originMaterial?.clone())
        )
        changeObj['lastColor'] = null
        changeObj['notChange'] = false
    } else {
        let colorObj = new THREE.Color(color)
        changeObj.traverse(child => {
            // child.geometry && child.material.color.set(colorObj)
            if (child.geometry) {
                child.material.color?.set(colorObj)
                child.material?.emissive?.set(colorObj)
            }
        })
        changeObj['lastColor'] = color
        changeObj['notChange'] = true
    }
}
/**
 *
 * @param {Number} equipmentType 设备类型
 * @param {String} module 区块类型
 * @param {Number} number 瓶子编号
 */
const botPutUp = (config) => {
    try {
        const { equipmentType, number } = config
        if (equipmentType !== 5) return
        // console.log(moduleObjs, equipmentType, module, number);
        let module = config.module == 'C1' ? 'C' : config.module
        let bot = moduleObjs[module][number - 1]
        if (!bot.isUp && moduleObjs && moduleObjs[module] && moduleObjs[module][number - 1]) {

            if (module == 'C') {
                bot.position.z = 20
            } else if (module == 'E1' || module == 'E2' || module == 'E3' || module == 'F') {
                bot.position.y = -185
            } else {
                bot.position.y = -20
                // console.log(module, bot.position.z);
            }


            bot['isUp'] = 1
        }
    } catch (error) {
        console.log(error)
    }

}

const botPutBack = (config) => {
    try {
        const { equipmentType, number } = config
        let module = config.module == 'C1' ? 'C' : config.module

        if (equipmentType == 5 && moduleObjs && moduleObjs[module]) {
            let bot = moduleObjs[module][number - 1]

            if (bot?.isUp) {
                if (module == 'C') {
                    bot.position.z = 0
                } else if (module == 'E1' || module == 'E2' || module == 'E3' || module == 'F') {
                    bot.position.y = -175.000
                } else {
                    bot.position.y = 0
                    // console.log(module, bot.position.z);
                }
                bot['isUp'] = 0
            }
        }
    } catch (error) {
        console.log(error)

    }




}

/**
 *
 * @param {Number} equipmentType 设备类型
 * @param {String} module 区块类型
 * @param {Number} number 瓶子编号
 * @param {String} content 内容
 * @param {String} show 是否显示
 *
 *
 */
const setLabelConent = (config) => {
    try {
        const { equipmentType, number, content, show } = config
        if (equipmentType == 5 && moduleObjs) {
            let module = config.module == 'C1' ? 'C' : config.module

            let changeObj = moduleObjs[module][number - 1].children.at(-1)
            content !== undefined && (changeObj.element.innerText = content)
            if (show) {
                changeObj.layers.mask = 1
                changeObj.show = true
            } else {
                changeObj.layers.mask = 0
                changeObj.show = false
            }
        }
    } catch (error) {
        console.log(error)
    }

}


const moduleHighlight = (equipmentType, module) => {
    try {
        if (equipmentType === '5') {
            // console.log(shelf);
            // let moduleArr = module.split()
            //  let moduleName = ${moduleArr[0]}-1
            shelf[module]['notChange'] = true
            shelf[module].traverse(child => {
                child.material = opacityMaterial.clone()
            })
        }
    } catch (error) {
        console.log(error)

    }

}
// const moveBot = (config) => {
//     const { equipmentType, from, to } = config
//     console.log(equipmentType, from, to);
//     if (equipmentType === 2) {
//         let staticFromObj = staticModuleObjs[from.module][from.number - 1]
//         let toObj = staticModuleObjs[to.module][to.number - 1]

//         let fromObj = staticModuleObjs[from.module][from.number - 1]


//         moduleObjs[fromObj.moduleName][fromObj.number - 1] = moduleObjs[toObj.moduleName][toObj.number - 1]
//         staticFromObj.visible = false
//         staticFromObj.traverse(child => {
//             child.notClick = true
//         })
//         toObj.visible = true
//         toObj.children.at(-1).element.innerText = `${from.number}号`


//         toObj.traverse(child => {
//             child.notClick = false
//             child.number = fromObj.number
//             child.moduleName = fromObj.moduleName
//         })

//     }
// }

const moveBot = (config) => {
    try {
        const { equipmentType, bot, position } = config
        // console.log(equipmentType, bot, position);
        if (equipmentType == 5 && moduleObjs) {
            botInit({ botObjs: moduleObjs, bot, position })

        }
    } catch (error) {
        console.log(error)

    }

}

const botInit = (config) => {
    try {
        const { botObjs, bot, position } = config
        const { x, y, col, betweenX, betweenY } = moduleAxisMap[position.module]
        let number = position.number * 1

        let yPoint = Math.ceil(number / col) - 1
        let xPoint = number % col == 0 ? col - 1 : number % col - 1
        let changeObj = botObjs[bot.module][bot.number - 1]
        if (position.module == 'D1' || position.module == 'D2') {
            yPoint += number > 12 ? 2 : number > 6 ? 1 : 0
        }
        if (changeObj && changeObj) {


            if ((bot.module == 'E1' || bot.module == 'E2') && bot.number * 1 > 18) {
                if ((position.module == 'E1' || position.module == 'E2') && position.number * 1 > 18) {
                    changeObj.position.z = 170
                    // console.log(position);
                } else {
                    // console.log(config);
                    changeObj.position.z = 200
                    // console.log(changeObj.position.z);
                }
            }
            // console.log(moduleObjs, changeObj, bot.module, bot.number);
            changeObj.position.x = x + betweenX * xPoint
            changeObj.position.y = y + betweenY * yPoint
            if (bot.module == 'E2') changeObj.position.x -= 120



        }

    } catch (error) {
        console.log(error)

    }

}

const tipsInit = () => { }

export { opacityMaterial, noModules, setColor, botPutUp, botPutBack, moduleHighlight, moveBot, setLabelConent, botInit, tipsInit }
