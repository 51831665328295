export const HFCR = (el) => {
    return [
        {
            title: '反应模块',
            blockName: 'A1',
            description: <div >每四个瓶子为一个通道，共2个通道，可对通道配置磁搅拌、加热、通气操作。</div >,
            target: () => el[0],
            placement: 'top',
            step: 4,
        },
        {
            title: '原料模块',
            blockName: ['B1','B2','B3'],
            description: <div >共有24个原料瓶和6个原料槽，均可放置实验所需的原料。右上方为清洗模组，可对针头进行清洗。</div >,
            target: () => el[0],
            placement: 'top',
            step: 5,
        },
        {
            title: '正压萃取模块',
            blockName: 'D3',
            description: <div >每四个通道为一组，可配置通气操作。</div >,
            target: () => el[0],
            placement: 'top',
            step: 6,
        },
        {
            title: '正压萃取模块',
            blockName: 'D1',
            description: <div >共有96个孔位，红色代表有液体的瓶子，可配置移液动作。</div >,
            target: () => el[0],
            placement: 'top',
            step: 7,
        },
        {
            title: '正压萃取模块',
            blockName: 'D2',
            description: <div >共有96个孔位，可配置清洗操作。</div >,
            target: () => el[0],
            placement: 'top',
            step: 8,
        },
        {
            title: '样品收集模块',
            blockName: 'E',
            description: <div >共有96个孔位，可对模块配置振荡操作，对每个瓶子配置移液操作。</div >,
            target: () => el[0],
            placement: 'top',
            step: 9,
        }
    ];
}

export const HFCR1= (el) => {
    return [
        {},
        {
            title: '添加原料',
            blockName: 'B1',
            description: <div >在原料模块单击选择任意瓶子添加实验原料。。</div >,
            target: () => el[0],
            prevButtonProps: { style: { display: 'none' } },
            placement: 'top',
            step: 1,
        }
    ];
}

export const HFCR2 = (el) => {
    return [
        {},
        {
            title: '填写原料和容量',
            description: <div >选择实验的原料并填写适当的容量。填写完成后请点击【提交】按钮。</div >,
            target: () => el[0],
            prevButtonProps: { style: { display: 'none' } },
            placement: 'right',
            step: 2,
        }
    ];
}

export const HFCR3 = (el) => {
    return [
        {},
        {
            title: '点击【下一步】',
            description: <div >实验原料添加完成后，点击【下一步】进入流程的配置。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'top',
            step: 3,
        }
    ];
}

export const HFCR4 = (el) => {
    return [
        {},
        {
            title: '移液',
            blockName: 'D2',
            description: <div >红色代表含有液体的容器，仅红色容器可单击进入移液动作的配置。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'top',
            step: 4,
        }
    ];
}

export const HFCR5 = (el) => {
    return [
        {},
        {
            title: '填写移液信息',
            description: <div >可选择液体移入的位置，即区块类型和点位序号；同时可根据需求填写注液量和注液速度。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'right',
            step: 5,
        }
    ];
}

export const HFCR6 = (el) => {
    return [
        {
            title: '点击反应模块',
            blockName: 'A1',
            description: <div >鼠标移入光热模块至绿色时，单击可配置反应模块的动作及参数。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}

export const HFCR7 = (el) => {
    return [
        {
            title: '配置反应动作和参数',
            description: <div >每四个瓶子为一个通道，每个通道可配置加热、磁搅拌和通气动作。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'right',
            step: 1,
        }
    ];
}
export const HFCR8 = (el) => {
    return [
        {
            title: '取样和进样',
            description: <div >根据实验需求，可在反应过程中特定时间选择取样或者进样。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}

export const HFCR9 = (el) => {
    return [
        {
            title: '点击正压萃取模块',
            blockName: 'D1',
            description: <div >鼠标移入正压萃取模块至绿色时，单击可配置正压萃取模块的动作及参数。</div >,
            target: () => el[0],
            placement: 'right',
            step: 1,
        },
    ];
}

export const HFCR10 = (el) => {
    return [
        {
            title: '配置移液动作',
            description: <div >可对正压萃取模块瓶子操作移液动作。</div >,
            target: () => el[0],
            placement: 'right',
            step: 1,
        },
    ];
}

export const HFCR11 = (el) => {
    return [
        {
            title: '点击正压萃取模块',
            blockName: 'D2',
            description: <div >鼠标移入正压萃取模块至绿色时，单击可配置正压萃取模块的动作及参数。</div >,
            target: () => el[0],
            placement: 'right',
            step: 1,
        },
    ];
}

export const HFCR12 = (el) => {
    return [
        {
            title: '配置清洗动作',
            description: <div >可对正压萃取模块孔位进行清洗，根据实验需求选择适当的清洗液，填写注液量和注液速度即可。</div >,
            target: () => el[0],
            placement: 'right',
            step: 1,
        },
    ];
}

export const HFCR13 = (el) => {
    return [
        {
            title: '点击样品收集模块',
            blockName: 'E',
            description: <div >鼠标移入样品收集模块至绿色时，单击可配置样品收集模块的动作及参数。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}

export const HFCR14 = (el) => {
    return [
        {
            title: '配置反应动作和参数',
            description: <div >样品收集模块可进行振荡操作，可设置转速和持续时间。</div >,
            target: () => el[0],
            placement: 'right',
            step: 1,
        },
    ];
}

export const HFCR15 = (el) => {
    return [
        {},
        {
            title: '流程展示',
            description: <div >配置好的动作将按照顺序展示在左侧。</div >,
            target: () => el[0],
            placement: 'left',
            step: 1,
        },
        {
            title: '编辑分组',
            description: <div >单击分组的编辑按钮，可修改分组的信息。</div >,
            target: () => el[1],
            placement: 'left',
            step: 2,
        },
    ];
}

export const HFCR16 = (el) => {
    return [
        {
            title: '编辑分组',
            description: <div >可修改分许的名称和循环次数。循环次数代表该分组下所有动作的循环次数，可对一组动作进行重复性实验。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}

export const HFCR17 = (el) => {
    return [
        {},
        {
            title: '点击【模拟】',
            description: <div >实验动作全部添加完成后，点击【模拟】进入实验模拟。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        }
    ];
}

export const HFCR18 = (el) => {
    return [
        {},
        {
            title: '点击【上一步】',
            description: <div >点击【上一步】可回到流程配置修改内容。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
        {
            title: '倍速',
            description: <div >模拟支持1倍速、8倍速、16倍速和32倍速。</div >,
            target: () => el[1],
            placement: 'top',
            step: 2,
        },
        {
            title: '点击【开始模拟】',
            description: <div >点击开始模拟代表模拟开始，即模拟实际机器的运行步骤。</div >,
            target: () => el[2],
            placement: 'top',
            step: 3,
        }
    ];
}

export const HFCR19 = (el) => {
    return [
        {
            title: '点击【开始实验】',
            description: <div >实验模拟结束，可点击【开始实验】进入到实验的开始页面。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}
export const HFCR20 = (el) => {
    return [
        {
            title: '点击【配方列表】',
            description: <div >实验模拟结束，也可点击【配方列表】回到配方的卡片页，运行实际设备。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}
export const HFCR21= (el) => {
    return [
        {
            title: '开始实验',
            description: <div >每个配方会生成一个卡片，点击卡片上的【开始】代表会实际运行实验室设备。<span style={ { fontWeight: 'bold' } }>引导结束了，可以去创建配方开始实验了！</span></div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}
export const HFCR22 = (el) => {
    return [
        {
            title: '暂停和结束',
            description: <div >暂停代表暂停模拟，结束代表结束本次模拟。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}
