// 配方列表页引导配置
export const home = (el) => {
    return [
        {

            // nextButtonProps: { disabled: true },
        },
        {
            title: '新手引导',
            description: <div>欢迎进入新手引导，首先请单击下方设备，进入该设备下的配方实验。</div>,
            target: () => el[0],
            placement: 'top',
            prevButtonProps: { style: { display: 'none' } },
            step: 1,
            // nextButtonProps: { disabled: true },
        },

    ];
}

// 配方列表页引导配置
export const createFormula = (el) => {
    return [
        {

        },
        {
            title: '新建配方和上传配方',
            description: '系统内有两种开启实验的模式，下面将分别介绍。',
            target: () => el[0],
            prevButtonProps: { style: { display: 'none' } },
            step: 2,
        },
        {
            title: '上传配方',
            description: '第一种为上传配方模式，点击【上传配方】，可将已有的配方导入系统。',
            target: () => el[1],
            step: 3,
        }
    ];
}
//上传配方引导
export const uploadFormula = (el) => {
    return [
        {

        },
        {
            title: '上传配方',
            description: '点击【上传配方文件】可将下载的配方导入系统',
            target: () => el[0],
            prevButtonProps: { style: { display: 'none' } },
            step: 4,
        }
    ];
}
//新建配方引导
export const addFormula = (el) => {
    return [
        {},
        {
            title: '新建配方',
            description: '第二种为新建配方模式，点击【+新建配方】，可建立新的配方。',
            target: () => el[0],
            prevButtonProps: { style: { display: 'none' } },
            step: 5,
        }
    ];
}

//新建配方引导
// export const addFormulaModal = (el) => {
//     return [
//         {
//
//         },
//         {
//             title: '填写名称和摘要',
//             description: '在右侧填写好配方名称和摘要后，方便后续使用和查找。添加完成后请点击右侧表单下方的【提交】按钮。',
//             target: () => el[0],
//             prevButtonProps: { style: { display: 'none' } },
//             step: 6,
//         }
//     ];
// }

// 配方列表页引导配置
export const summaryInfo = (el) => {
    return [
        {},
        {
            title: '填写名称和摘要',
            description: <div>在右侧<span style={ { color: "#1677ff" } }>填写好配方名称和配方摘要</span>，方便后续使用和查找。添加完成后请点击右侧表单下方的【提交】按钮。</div>,
            target: () => el[0],
            prevButtonProps: { style: { display: 'none' } },
            placement: 'left',
            step: 6,
        },
    ];
}
