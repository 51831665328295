const moduleAxisMap = {
    'A': { x: -547.5 + 32, y: -170 + 15, betweenX: 40, betweenY: 40, col: 1 },
    'B': { x: -408 + 17.5, y: 150, betweenX: 0, betweenY: 0, col: 1 },
    'C': { x: -408, y: -170 + 45, betweenX: 35, betweenY: 35, col: 2 },
    'D1': { x: -262 + 3, y: -170 - 3, betweenX: 40, betweenY: 35, col: 2 },
    'D2': { x: -143 + 3, y: -170 - 3, betweenX: 40, betweenY: 35, col: 2 },
    'E1': { x: -39.5 + 4, y: -177.5, betweenX: 35, betweenY: 32, col: 3 },
    'E2': { x: 80.5 + 4, y: -177.5, betweenX: 35, betweenY: 32, col: 3 },
}

export { moduleAxisMap }