const eventList = {};

/**
 * useage:
 * import bus from '~/utils/hooks/eventBus'
 *
 * bus.$on('eventName', (params) => {})
 *
 * bus.$emit('eventName', params)
 *
 * bus.$off('eventName', callback)
 *
 * 注意：订阅发布存在内存泄漏风险，需要在组件销毁时手动取消订阅。
 */

// 发布topic，注册回调
const $on = function (eventName, callback) {
  if (!eventList[eventName]) {
    eventList[eventName] = [];
  }
  //   console.log("🚀 ~ eventList:", eventList);
  eventList[eventName].push(callback);
};

// 订阅topic，触发回调
const $emit = function (eventName, params) {
  if (eventList[eventName]) {
    var arr = eventList[eventName];
    arr.forEach((cb) => {
      cb(params);
    });
  }
};

// 取消订阅
const $off = function (eventName, callback) {
  if (eventList[eventName]) {
    if (callback) {
      var index = eventList[eventName].indexOf(callback);
      eventList[eventName].splice(index, 1);
    } else {
      eventList[eventName].length = 0;
    }
  }
};

export default {
  eventList,
  $on,
  $emit,
  $off,
};
