import { useState, useEffect } from "react";
import { Button, Space } from "antd";
import "./index.scss";
import excel from "../../images/excel.svg";
import pdf from "../../images/pdf.svg";
import word from "../../images/word.svg";
export default function UserView({ sId, msg }) {
  // useEffect(() => {
  //   console.log("🚀 ~ UserView ~ msg:", msg);
  // }, [msg]);
  // const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  return (
    <div className="chatFlowItem user " id={sId}>
      <div className="avatar">
        {/* {userInfo?.account && <img alt="" src={userInfo?.account} />} */}
        {/* {userInfo?.account && <i className="iconfont icon-fa-user"></i>} */}
      </div>
      <div className="chatContent">
        <div className="chatText">
          {msg?.content}
          {msg?.fileUrl && (
            <div className="chatFiles">
              <span className="fileName longTextShowOneLine">
                <img className="fileIcon" src={pdf} alt="" />
                {msg?.fileName}
              </span>
              {/* <i className="filePreview iconfont icon-chakan"></i> */}
              <i
                className="filePreview iconfont icon-xiazai"
                // onClick={downLoadFile}
              ></i>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
