// 初始化状态
export const sysStatusInfo = {
  nav: "chat",
  isRecording: false,
  messageInfo: {
    msgId: "",
    isSending: false,
  },
  autoComplete: false,
};
