
import React, { lazy, } from "react";

import { nanoid } from 'nanoid'

const ZZTController = lazy(() => import('~/views/demo/smartLab'))
const MultiDevice = lazy(() => import('~/views/demo/multiDevice'))
const CFpanel = lazy(() => import('~/views/demo/CFpanel'))
const HT = lazy(() => import('~/views/demo/HT'))



const BroadcastControllerHT = lazy(() => import('~/views/demo/mobile/broadcastControllerHT'))

const BroadcastControllerCF = lazy(() => import('~/views/demo/mobile/broadcastControllerCF'))


const BigScreen = lazy(() => import('~/views/demo/bigScreen'))
const ScreenLabStatistics = lazy(() => import('~/views/demo/bigScreen/screen-lab-statistics'))
const ScreenBroadcast = lazy(() => import('~/views/demo/bigScreen/screen-broadcast'))
const ScreenDigitalTwin = lazy(() => import('~/views/demo/bigScreen/screen-digital-twin'))
const ScreenExperimentalData = lazy(() => import('~/views/demo/bigScreen/screen-experimental-data'))
const ScreenMaterial = lazy(() => import('~/views/demo/bigScreen/screen-material'))
const ScreenMonitor = lazy(() => import('~/views/demo/bigScreen/screen-monitor'))

//水热模拟树根图
const RingRoot = lazy(() => import('~/views/demo/ringRoot/ringRoot'))


const Helux = lazy(() => import('~/views/demo/helux'))

const SmartLab = [
    {
        path: '/helux', element: <Helux />,
    },
    {
        path: '/smartLab', element: <ZZTController />,
    },
    {
        path: '/ringRoot', element: <RingRoot />,
    },
    {
        path: '/multiDevice', element: <MultiDevice />,
    },
    {
        path: '/cfPanel', element: <CFpanel />,
    },
    {
        path: '/HTPanel', element: <HT />,
    },
    {
        path: '/mobile/broadcastControllerHT', key: nanoid(), element: <BroadcastControllerHT />, name: '日志播报（水热）',
    },
    {
        path: '/mobile/broadcastControllerCF', key: nanoid(), element: <BroadcastControllerCF />, name: '日志播报（连续流）',
    },
    {
        path: '/bigScreen', element: <BigScreen />,
    },
    {
        path: "/screen-lab-statistics",
        element: <ScreenLabStatistics />
    },
    {
        path: "/screen-broadcast",
        element: <ScreenBroadcast />
    },
    {
        path: "/screen-digital-twin",
        element: <ScreenDigitalTwin />
    },
    {
        path: "/screen-experimental-data",
        element: <ScreenExperimentalData />
    },
    {
        path: "/screen-material",
        element: <ScreenMaterial />
    },
    {
        path: "/screen-monitor",
        element: <ScreenMonitor />
    },
]
export default SmartLab 