import React, { lazy, Suspense } from "react";

const CubeRoot = lazy(() => import("~/views/cubeRoot/cubeRoot"));

const CubeRootPage = [
  {
    path: "/cubeRoot",
    element: <CubeRoot />,
  },
];
export default CubeRootPage;
