const cModuleMaterial = [['Boc-L-serine', 'DCM'], ['二甲羟胺盐酸盐', 'DCM'], ['NMM', 'DCM'], ['EDCI', 'DCM'], ['Et3N', 'DCM'], ['HOBT', 'DCM'], ['Boc-L-serine', 'DMF'], ['二甲羟胺盐seal酸', 'DMF'], ['DIPEA', 'DMF'], ['HBTU', 'DMF'], ['Et3N', 'DMF'], ['稀盐酸', '淬灭反应']]

const arm = [['x', [31, 70 + 20, 0], 'x', 'x'], ['y', [31, 60 + 20, -31], '机械臂y', 'y'], ['z', [30, 33 + 20, -35], '机械臂z', 'z'], ['r', [30.5, 15 + 20, -33], '机械臂r', 'r']]
const moduleSet = [['grap', [12, -5 + 20, -33], '抓夹', 'grap'], ['seal', [-33, -5 + 20, -30], '封盖', 'seal'], ['mlModule', [31, -10 + 20, -30], '移液', 'mlModule']]
const module = [
    // ['sAdd', [-39, -20 + 20, 3], '固体加料', 'module'],
    ['shake', [-27, -30 + 20, 3], '摇晃搅拌模块', 'module'],
    ['shake2', [-9, -30 + 20, 3], '摇晃搅拌模块', 'module'],
    ['lStore', [6, -30 + 20, 3], '原料存放模块', 'module'],
    ['lStore2', [17, -30 + 20, 3 + 25], 'C2', 'module'],
    ['SPESupport', [19, -30 + 20, 3], 'SPE色谱柱模块', 'module'],
    ['seSupport', [31, -30 + 20, 3], '固相萃取柱存放模块', 'module'],
    ['wasteLiquid', [28, -39 + 20, -30], '废液槽', 'module'],
    // ['sixWay', [0, -15, -15], '六通', 'module'],
    ['lcms', [-5, -14, -21], '色质谱进样仓', 'module'],
    ['roman', [-13, -15, -15], '拉曼进样仓', 'module'],
]
const initModule = [
    ['shake', [-27, -30 + 20, 3], `A`, 'module'],
    ['shake2', [-9, -30 + 20, 3], 'B', 'module'],
    ['lStore', [6, -30 + 20, 3], 'C', 'module'],
    ['lStore2', [17, -30 + 20, 3 + 25], 'C2', 'module'],
    ['SPESupport', [19, -30 + 20, 3], 'D', 'module'],
    ['seSupport', [31, -30 + 20, 3], 'E', 'module'],
]

const otherModule = [
    ['wasteLiquid', [28, -39 + 20, -30], 'NWD', 'module'],
    // ['sixWay', [0, -5, -15], '六通', 'module'],
    ['lcms', [-5, -4, -21], 'MS', 'module'], // 色谱进样仓 
    ['roman', [-13, -5, -15], 'S', 'module'], // 拉曼
]
// const module = [['lStore', [6, -30, 3], '原料存放模块', 'module']]
const frame = [['frame', [0, 0 + 20, 0], '外壳', 'border']]

const moduleFunctionMap = {
    'A': '振摇、真空、通氮、注液、加热、制冷',
    'B': '振摇、真空、通氮、注液、加热、制冷',
    'C': '吸液、盖帽、锁帽',
    'D': '注液、盖帽、锁帽',
    'E': '抓取、放下',
    'E1': '抓取、放下、注液',
    'E2': '抓取、放下、注液',

}

const moduleFunctionMap1 = {
    'A': '加热、制冷',
    'B': '加热、制冷',
    'C2': '设置原料',
    'C': '设置原料'
}

const a0 = [679, 5, 140, -110.5]
const b0 = [501.3, 140, -110.5]
const c0 = [345.1, 137.5, -124]
const d0 = [226.4, 135, -91]
const capD0 = [226.4, -15, -91]
const lockOffsetD0 = [227.5, 35, -91]

const offsetD0 = [226.4, -65, -91]

const n1 = [54, 55, 15, 16, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 63]
const n2 = [37, 38, 30, 31, 32, 33, 36, 39, 40, 41, 42, 43]
const n3 = [0, 1, 2, 3, 4, 5, 6, 7, 27, 28, 29, 34, 35]
const n4 = [19, 20, 14, 17, 18, 21, 22, 23, 24, 25, 26]

const needleTrans = {
    1: 1.5,
    2: 0.5,
    3: -0.5,
    4: -1.5
}
const aTempInit = { 1: -15, 2: -15, 3: 0, 4: 25 }


const deviceModuleMap = {
    1: "移液模块",
    2: "抓取模块",
    3: "封盖模块",
};
const deviceStatusMap = {
    1: "系统正常，可执行自动指令",
    2: "系统正常，需要执行复位指令",
    3: "系统异常，需要执行故障清除指令",
};
const workInstructionMap = {

    1: "切换吸液模块",
    2: "切换抓取模块",
    3: "切换封盖模块",
    10: "放回模块",
    11: "放帽",
    12: "锁帽",
    13: '抓',
    14: "放",
    15: "吸液",
    16: "注液",
    20: "急停",
    21: "复位",
    22: "故障清除",
    23: "门上",
    24: "门下",
    25: "开仓",
    26: "关仓",
    27: "通氮气",
    30: "切换手动",
    31: "切换自动"
};

const botPositionYMap = {
    'A': 2.145,
    'B': 2.145,
    'C': -0.85,
    'C2': -0.85,
    'D': 0.85,
    'E': 2.15,

}

const absNeedle = ['absorb_volume_4', 'absorb_volume_3', 'absorb_volume_2', 'absorb_volume_1']
const injNeedel = ['injection_volume_4', 'injection_volume_3', 'injection_volume_2', 'injection_volume_1']

const channelA = [
    [
        [-35, -30 + 20, 20],
        [-20, -30 + 20, 20],
        [-20, -30 + 20, 12],
        [-35, -30 + 20, 12],
        [-35, -30 + 20, 20],
    ],
    [
        [-35, -30 + 20, 12],
        [-20, -30 + 20, 12],
        [-20, -30 + 20, 4],
        [-35, -30 + 20, 4],
        [-35, -30 + 20, 12],
    ],
    [
        [-35, -30 + 20, 4],
        [-20, -30 + 20, 4],
        [-20, -30 + 20, -4],
        [-35, -30 + 20, -4],
        [-35, -30 + 20, 4],
    ],
    [
        [-35, -30 + 20, -4],
        [-20, -30 + 20, -4],
        [-20, -30 + 20, -13],
        [-35, -30 + 20, -13],
        [-35, -30 + 20, -4],
    ]
]

const channelB = [
    [
        [-15, -30 + 20, 20],
        [-0, -30 + 20, 20],
        [-0, -30 + 20, 12],
        [-15, -30 + 20, 12],
        [-15, -30 + 20, 20],
    ],
    [
        [-15, -30 + 20, 12],
        [-0, -30 + 20, 12],
        [-0, -30 + 20, 4],
        [-15, -30 + 20, 4],
        [-15, -30 + 20, 12],
    ],
    [
        [-15, -30 + 20, 4],
        [-0, -30 + 20, 4],
        [-0, -30 + 20, -4],
        [-15, -30 + 20, -4],
        [-15, -30 + 20, 4],
    ],
    [
        [-15, -30 + 20, -4],
        [-0, -30 + 20, -4],
        [-0, -30 + 20, -13],
        [-15, -30 + 20, -13],
        [-15, -30 + 20, -4],
    ]
]

const moduleA = [
    [-35, -30 + 20, 20],
    [-20, -30 + 20, 20],
    [-20, -30 + 20, -13],
    [-35, -30 + 20, -13],
    [-35, -30 + 20, 20]]

const moduleB = [
    [-17, -30 + 20, 20],
    [-2, -30 + 20, 20],
    [-2, -30 + 20, -13],
    [-17, -30 + 20, -13],
    [-17, -30 + 20, 20]]

const moduleAN2 = [
    [-35, -30 + 20, 28],
    [-20, -30 + 20, 28],
    [-20, -30 + 20, -19],
    [-35, -30 + 20, -19],
    [-35, -30 + 20, 28],
]

const moduleBN2 = [
    [-15, -30 + 20, 28],
    [-0, -30 + 20, 28],
    [-0, -30 + 20, -19],
    [-15, -30 + 20, -19],
    [-15, -30 + 20, 28],
]

const functionCodeMap = {
    'prepareCover': '封盖模块组装'
    , 'aspiration': '移液-吸液'
    , 'inject': '移液-注液'
    , 'prepareCapture': '抓取模块组装'
    , 'preparePutBack': '模块放回'
    , 'prepareAspiration': '移液模块组装'
    , 'downDoor': '门下'
    , 'upDoor': '门上'
    , 'putHat': '封盖-放帽'
    , 'lockCap': '封盖-锁帽'
    , 'capture': '抓取-抓取'
    , 'putBack': '抓取-放下',
    'shockAreaA': '振摇-区域A',
    'shockAreaB': '振摇-区域B',
    'shockAreaABack': '振摇-归零A',
    'shockAreaAStop': '振摇-停止A',
    'shockAreaBStop': '振摇-停止B',
    'shockAreaBBack': '振摇-归零B',
    'heatWayAOne': '加热-区域A通道1',
    'heatWayATwo': '加热-区域A通道2',
    'heatWayAThree': '加热-区域A通道3',
    'heatWayAFour': '加热-区域A通道4',
    'heatWayBOne': '加热-区域B通道1',
    'heatWayBTwo': '加热-区域B通道2',
    'heatWayBThree': '加热-区域B通道3',
    'heatWayBFour': '加热-区域B通道4',
    'currentTempA': '加热-当前温度-区域A',
    'currentTempB': '加热-当前温度-区域B',
    'ultrasonicCleanOpen': '超声清洗-开',
    'ultrasonicCleanStop': '超声清洗-关',
    'vacuumA': '真空-模块A',
    'vacuumB': '真空-模块B',
    'vacuumD': '真空-模块D',
    'vacuumAStop': '真空-模块停止A',
    'vacuumBStop': '真空-模块停止B',
    'vacuumDStop': '真空-模块停止D',
    'nitrogenousA': '通氮-模块A',
    'nitrogenousB': '通氮-模块B',
    'nitrogenousAStop': '通氮-模块停止A',
    'nitrogenousBStop': '通氮-模块停止B',
    'lowTemperatureA': '低温-区域A',
    'lowTemperatureB': '低温-区域B',
    'lowTemperatureAStop': '低温-停止A',
    'lowTemperatureBStop': '低温-停止B',
    'lowTemperatureACurrent': '低温-当前温度-区域A',
    'lowTemperatureBCurrent': '低温-当前温度-区域B',
    'emergencyStop': '急停',
    'sixWaySampleUp': '六通切换阀-上样',
    'sixWaySampleIn': '六通切换阀-进样',
    'sixWayStop': '六通切换阀-关闭',
    'spectrum': '拉曼光谱',
    'multFunctionCommon': '多功能任务',
    'spectrometrySixWaySampleUp': '色谱上样',
    'spectrometrySixWaySampleIn': '色谱进样',
    'sixWaySample': '光谱切换阀',
    'spectrometrySixWaySample': '质谱切换阀',
    'heatWayAOneStop': '加热-区域A通道1停止',
    'heatWayATwoStop': '加热-区域A通道2停止',
    'heatWayAThreeStop': '加热-区域A通道3停止',
    'heatWayAFourStop': '加热-区域A通道4停止',
    'heatWayBOneStop': '加热-区域B通道1停止',
    'heatWayBTwoStop': '加热-区域B通道2停止',
    'heatWayBThreeStop': '加热-区域B通道3停止',
    'heatWayBFourStop': '加热-区域B通道4停止',
    'wasteDischarge': '排废',
    'needleClean': '针头清洗'



}

const mlScreenOjb = {
    A: { between: 4, screen0: [-24, -25 + 20 + 5, 16 + 1] },
    B: { between: 4, screen0: [-7, -25 + 20 + 5, 16 + 1] },
    C: { between: 5.5, screen0: [10, -26 + 20, 16 + 1] },
    D: { between: 3, screen0: [16 + 6, -3, 15 + 1] },
    E1: { between: 6, screen0: [16 + 6, -3, 15 + 1] },
    E2: { between: 6, screen0: [16 + 6, -3, 15 + 1] },

    C1: { between: 5.5, s0: [5, -26 + 20, 16 + 10.5] },
    spectrometryInject: { between: 5.5, s0: [0.5, 0, -24.5] },
    spectrumInject: { between: 5.5, s0: [-2, -2, -24.5] }
}
export {
    cModuleMaterial, arm, moduleSet, module, initModule, otherModule, frame, a0, b0, c0, d0, capD0, n1, n2, n3, n4, needleTrans, aTempInit, deviceModuleMap, deviceStatusMap, workInstructionMap, absNeedle, injNeedel, channelA, channelB, moduleA, moduleB, moduleAN2, moduleBN2, offsetD0, functionCodeMap, lockOffsetD0, botPositionYMap, moduleFunctionMap, moduleFunctionMap1, mlScreenOjb
}





