

import request from "./modules/request.js";


export function getPointList(params) {
    return request({
        url: "/formula/basis-info/pointList",
        method: "post",
        params: params,
    });
}


export function getAllBlockFunc() {
    return request({
        url: "/block/basis-block-info/allBlockFunc",
        method: "post",
    });
}


export function queryFormulaBasisNameIsExist(params) {
    return request({
        url: "/formula/basis-info/queryFormulaBasisNameIsExist",
        method: "post",
        params: params,
    });
}