
const functionCodeMap = {
    'commonAspiration': '吸液',
    'commonInject': '注液'
}

const coordMap = {
    A: 1,
    B: 2,
    C: 2,
    D: 2,
    E: 3,
    F: 3,
    G1: 3,
    G2: 2,
    G3: 6
}

const moduleFunMap = {
    A: { name: '氮吹模块', function: ['通氮', '拿起-放下'] },
    B: { name: '封盖模块', function: [] },
    C: { name: '振摇模块', function: ['振摇', '拿起放下'] },
    D1: { name: '水热反应模块Ⅰ ', function: ['加热', '拿起-放下'] },
    D2: { name: '水热反应模块Ⅱ', function: ['加热', '拿起-放下'] },
    E1: { name: '反应容器模块Ⅰ', function: ['注液', '拿起-放下'] },
    E2: { name: '反应容器模块Ⅱ', function: ['注液', '拿起-放下'] },
    F: { name: '原料模块', function: ['吸液-注液'] },
    D11: { name: '水热反应模块Ⅰ 通道1', function: ['加热'] },
    D12: { name: '水热反应模块Ⅰ 通道2', function: ['加热'] },
    D13: { name: '水热反应模块Ⅰ 通道3', function: ['加热'] },

    D24: { name: '水热反应模块Ⅱ 通道4', function: ['加热'] },
    D25: { name: '水热反应模块Ⅱ 通道5', function: ['加热'] },
    D26: { name: '水热反应模块Ⅱ 通道6', function: ['加热'] },
}

const n21 = [
    [-5 + 0.5, -4, 2 + 1.2],
    [-3.7 + 0.5, -4, 2 + 1.2],

    [-3.7 + 0.5, -4, -2 + 1.2],
    [-5 + 0.5, -4, -2 + 1.2],

    [-5 + 0.5, -4, 2 + 1.2],
]

const d1Heating1 = [
    [-3.2 + 1.9, -1.5 - 2.5, 1.6 + 1.8],
    [-2.2 + 1.9, -1.5 - 2.5, 1.6 + 1.8],

    [-2.2 + 1.9, -1.5 - 2.5, 0.4 + 1.8],
    [-3.2 + 1.9, -1.5 - 2.5, 0.4 + 1.8],

    [-3.2 + 1.9, -1.5 - 2.5, 1.6 + 1.8],
]

const grabMap = {
    'A': { x: -80, y: 620.257 + 130 - 200, betweenX: 40, betweenY: 40, col: 1 },
    'B': { x: -205, y: 300 - 60, betweenX: 0, betweenY: 0, col: 1 },
    'spectrum': { x: -150, y: 200, betweenX: 0, betweenY: 0, col: 1 },

    'C': { x: -153 - 35, y: 620.257 - 33 - 70, betweenX: 35, betweenY: 35, col: 2 },
    'D1': { x: -550 + 220 + 35 - 40, y: 651.257 - 82, betweenX: 40, betweenY: 35, col: 2 },
    'D2': { x: -550 + 220 + 35 - 40 - 120, y: 651.257 - 82, betweenX: 40, betweenY: 35, col: 2 },
    'E1': { x: -560, y: 471.257 + 100, betweenX: 35, betweenY: 32, col: 3 },
    'E2': { x: -560 - 40 * 3, y: 471.257 + 100, betweenX: 35, betweenY: 32, col: 3 }
}

export { functionCodeMap, coordMap, n21, d1Heating1, moduleFunMap, grabMap }