
export const modelInfoSR = (el) => {
    return [
        {
            title: '试剂瓶区',
            blockName: 'F',
            description: <div >用于放置实验所需的原料。</div >,
            target: () => el[0],
            placement: 'top',
            step: 4,
        },
        {
            title: '反应瓶区',
            blockName: 'E2',
            description: <div >试管区可注入不同原料成为混合液体，用于存放实验反应液体。</div >,
            target: () => el[0],

            placement: 'top',
            step: 5,
        },

        {
            title: '加热区',
            blockName: 'D2',
            description: <div >用于对反应容器进行加热，6 个孔位为一个温区，每个温区可单独控制。</div >,
            target: () => el[0],

            placement: 'top',
            step: 6,
        },
        {
            title: '振荡区',
            blockName: 'C',
            description: <div >用于对反应容器进行振荡操作，内有6个孔位，可配置转速和持续时间。</div >,
            target: () => el[0],

            placement: 'top',
            step: 7,
        },
        {
            title: '氮吹区',
            blockName: 'A',
            description: <div >用于对反应容器进行气体吹扫操作，需外接气源，内有9个孔位，可配置流速和持续时间。</div >,
            target: () => el[0],
            placement: 'top',
            step: 8,
        }
    ];
}


export const createGroupSR = (el) => {
    return [
        {},
        {
            title: '点击【新增分组】',
            description: <div >新建动作前必须要选择一个分组。</div >,
            target: () => el[0],
            prevButtonProps: { style: { display: 'none' } },
            placement: 'left',
            step: 12,
        },
    ];
}

export const ZZTC3 = (el) => {
    return [
        {},
        {
            title: '添加原料',
            blockName: 'F',
            description: <div >在原料模块单击选择任意瓶子添加实验原料。</div >,
            target: () => el[0],
            prevButtonProps: { style: { display: 'none' } },
            placement: 'top',
            step: 1,
        }
    ];
}
export const ZZTC4 = (el) => {
    return [
        {},
        {
            title: '填写原料和容量',
            description: <div >选择实验的原料并填写适当的容量。填写完成后请点击【提交】按钮。</div >,
            target: () => el[0],
            prevButtonProps: { style: { display: 'none' } },
            placement: 'right',
            step: 2,
        }
    ];
}

export const ZZTC5 = (el) => {
    return [
        {},
        {
            title: '点击【下一步】',
            description: <div >实验原料添加完成后，点击【下一步】进入流程的配置。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'top',
            step: 3,
        }
    ];
}

export const ZZTC6 = (el) => {
    return [
        {},
        {
            title: '移液',
            blockName: 'E2',
            description: <div >红色代表含有液体的容器，仅红色容器可单击进入移液动作的配置。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'top',
            step: 4,
        }
    ];
}

export const ZZTC7 = (el) => {
    return [
        {},
        {
            title: '填写注液信息',
            description: <div >可选择液体移入的位置，即区块类型和点位序号；同时可根据需求填写注液量和注液速度。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'right',
            step: 5,
        }
    ];
}

export const ZZTC8 = (el) => {
    return [
        {},
        {
            title: '移动瓶子',
            blockName: 'E2',
            description: <div >红色代表含有液体的容器，仅红色容器可单击进入移动瓶子操作。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'top',
            step: 6,
        },
    ];
}
export const ZZTC9 = (el) => {
    return [
        {},
        {
            title: '填写移动瓶子信息',
            description: <div >可根据实验需要选择移入目标位置，即区块类型和点位序号。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'right',
            step: 7,
        },
    ];
}

export const ZZTC10 = (el) => {
    return [
        {
            title: '点击氮吹模块',
            blockName: 'A',
            description: <div >鼠标移入有瓶子的氮吹模块至绿色时，单击可配置氮吹模块的参数。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}
export const ZZTC11 = (el) => {
    return [
        {
            title: '配置氮吹模块参数',
            description: <div >氮吹模块可配置流速和持续时间，流速可配置1至5的整数。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'right',
            step: 1,
        },
    ];
}
export const ZZTC12 = (el) => {
    return [
        {
            title: '点击振荡模块',
            blockName: 'C',
            description: <div >鼠标移入有瓶子的振荡模块至绿色时，单击可配置振荡模块的参数。</div >,
            prevButtonProps: { style: { display: 'none' } },
            target: () => el[0],
            placement: 'top',
            step:1,
        },
    ];
}
export const ZZTC13 = (el) => {
    return [
        {
            title: '配置振荡模块参数',
            description: <div >振荡模块可配置转速和持续时间，转速的范围为60至1020的整数。</div >,
            target: () => el[0],
            placement: 'right',
            step: 1,
        },
    ];
}
export const ZZTC14 = (el) => {
    return [
        {
            title: '点击加热模块',
            blockName: 'D2',
            description: <div >鼠标移入有瓶子的加热模块至绿色时，单击可配置加热模块的参数。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}
export const ZZTC15 = (el) => {
    return [
        {
            title: '配置加热模块参数',
            description: <div >加热模块可配置温度和持续时间，温度的范围为25至300的一位小数。</div >,
            target: () => el[0],
            placement: 'right',
            step: 1,
        },
    ];
}
export const ZZTC16 = (el) => {
    return [
        {},
        {
            title: '流程展示',
            description: <div >配置好的动作将按照顺序展示在左侧。</div >,
            target: () => el[0],
            placement: 'left',
            step: 1,
        },
        {
            title: '编辑分组',
            description: <div >单击分组的编辑按钮，可修改分组的信息。</div >,
            target: () => el[1],
            placement: 'left',
            step: 2,
        },
    ];
}
export const ZZTC17 = (el) => {
    return [
        {
            title: '编辑分组',
            description: <div >可修改分许的名称和循环次数。循环次数代表该分组下所有动作的循环次数，可对一组动作进行重复性实验。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}

export const ZZTC18 = (el) => {
    return [
        {},
        {
            title: '点击【模拟】',
            description: <div >实验动作全部添加完成后，点击【模拟】进入实验模拟。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        }
    ];
}

export const ZZTC19 = (el) => {
    return [
        {},
        {
            title: '点击【上一步】',
            description: <div >点击【上一步】可回到流程配置修改内容。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
        {
            title: '倍速',
            description: <div >模拟支持1倍速、8倍速、16倍速和32倍速。</div >,
            target: () => el[1],
            placement: 'top',
            step: 2,
        },
        {
            title: '点击【开始模拟】',
            description: <div >点击开始模拟代表模拟开始，即模拟实际机器的运行步骤。</div >,
            target: () => el[2],
            placement: 'top',
            step: 3,
        }
    ];
}
export const ZZTC20 = (el) => {
    return [
        {
            title: '暂停和结束',
            description: <div >暂停代表暂停模拟，结束代表结束本次模拟。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}
export const ZZTC21 = (el) => {
    return [
        {
            title: '点击【开始实验】',
            description: <div >实验模拟结束，可点击【开始实验】进入到实验的开始页面。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}
export const ZZTC22 = (el) => {
    return [
        {
            title: '点击【配方列表】',
            description: <div >实验模拟结束，也可点击【配方列表】回到配方的卡片页，运行实际设备。</div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}
export const ZZTC23 = (el) => {
    return [
        {
            title: '开始实验',
            description: <div >每个配方会生成一个卡片，点击卡片上的【开始】代表会实际运行实验室设备。<span style={ { fontWeight:'bold' } }>引导结束了，可以去创建配方开始实验了！</span></div >,
            target: () => el[0],
            placement: 'top',
            step: 1,
        },
    ];
}

