import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { getSearch } from "~/utils/jstools";
import { downloadFormula } from "~/api/formula-basis";
export default function GeneFormulaFile({
  userInfo,
  sId,
  sendMessage,
  formulaFiles,
}) {
  const equipmentMap = {
    1: "异木棉MC01",
    3: "异木棉HT01",
    5: "异木棉PC01",
    6: "异木棉MC02",
    7: "异木棉HT02",
    2: "木棉FS01",
    4: "木棉QD01",
  };
  const fileItems = [
    {
      equipmentType: 7,
      deviceNumber: "HTIKI02231024",
      formulaId: "1852195326537023489",
    },
    {
      equipmentType: 10,
      deviceNumber: "ACP12I01240910",
      formulaId: "1847232875891077121",
    },
  ];

  const [ff, setFf] = useState([...formulaFiles]);
  useEffect(() => {
    console.log(ff, formulaFiles);
  }, []);
  const handleRedirect = (device) => {
    goFormaluList({ ...device });
  };

  const navigate = useNavigate();

  const goFormaluList = (item) => {
    // if (item.equipmentType == 5) return message.warning('该设备暂未开放')
    sessionStorage.setItem("deviceInfo", JSON.stringify(item));
    navigate("/formulaList");
  };

  const handleDownload = (item) => {
    downloadFormula({
      basisId: item.id,
      encrypt: 1,
    }).then((res) => {
      let url = window.URL.createObjectURL(res); // 创建一个临时的url指向blob对象
      // 创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
      let a = document.createElement("a");
      a.href = url;
      a.download = `${item.deviceNumber}.ff`;
      a.click();
      // 释放这个临时的对象url
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <div className="chatFlowItem robot" id={sId}>
      <div>
        <h3>自动化实验文件</h3>
        <div>
          {ff?.map((item) => {
            return (
              <div style={{ marginTop: "10px" }}>
                <Button
                  type="link"
                  style={{ width: "200px" }}
                  onClick={() => handleRedirect(item)}
                >{`使用${equipmentMap[item.equipmentType]}设备`}</Button>
                <Button type="primary" onClick={() => handleDownload(item)}>
                  点击自动化下载文件
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
