import * as THREE from 'three'
// import { coordMap } from './hardwareConfig';
import { moduleObjs, heatObjs, shelf, staticModuleObjs, xAxis, yAxis, planeObj } from '../index'
import { moduleAxisMap } from './hardWareConfig';
import { injectBot, reaction, peristalticBot } from '../../quantumDot';
import { defaultAnimateLayoutChanges } from '@dnd-kit/sortable';


const opacityMaterial = new THREE.MeshBasicMaterial({
    color: 0x00ff00,     // 颜色
    transparent: true,    // 是否开启使用透明度
    opacity: 0.3,        // 透明度
    depthWrite: false,    // 关闭深度写入 透视效果
    side: THREE.DoubleSide, // 双面显示
});

const noModules = ['bot', 'n2', 'tip', '100ml', 'cap']

// /**
//  * 
//  * @param {*} module :模块类型
//  * @param {*} col :行y
//  * @param {*} row :列x
//  */
// const coordToNum = (module, row, col) => {
//     let index
//     if (module === 'G') {
//         index = col < 7 ? (row - 1) * coordMap['G1'] + col : col > 7 ? (row - 1) * coordMap['G3'] + col : 18 + col
//     }
//     index = (row - 1) * coordMap[module] + col

//     return index
// }


// const numToCoord = (moduleName, number) => {
//     let returnObj = { col: 0, row: 0 }
//     if (moduleName === 'G') {
//         if (number < 19) {
//             returnObj.row = Math.ceil(number / coordMap['G1'])
//             returnObj.col = number % coordMap['G1'] || coordMap['G1']
//         } else if (number < 21) {
//             returnObj.row = Math.ceil((number - 18) / coordMap['G2']) + 6
//             returnObj.col = (number - 18) % coordMap['G2'] || coordMap['G2']
//         } else if (number < 44) {
//             // console.log(moduleName, number, coordMap['G3']);
//             returnObj.row = Math.ceil((number - 20) / coordMap['G3']) + 7
//             returnObj.col = (number - 20) % coordMap['G3'] || coordMap['G3']
//         }
//     } else {
//         returnObj.row = Math.ceil(number / coordMap[moduleName])
//         returnObj.col = number % (coordMap[moduleName]) || coordMap[moduleName]
//     }
//     return returnObj
// }

/**
 * 
 * @param {String} equipmentType :设备类型
 * @param {String} module :模块类型
 * @param {Number} number :瓶子编号
 * @param {rgb} color :颜色
 */
const setColor = (config) => {
    try {
        const { equipmentType, module, number, color } = config

        let changeObj
        let isObjArr = false
        if (equipmentType == 7) {
            // console.log(config);
            if (number && moduleObjs && moduleObjs[module]) {
                if (!isNaN(parseFloat(number)) && isFinite(number) && moduleObjs[module][number - 1]) {
                    changeObj = moduleObjs[module][number - 1]
                } else {
                    let num = number.substr(-1) * 1
                    if (module == 'D2') {
                        num = num - 3
                    }
                    // console.log(config);

                    changeObj = heatObjs[module][num - 1]

                }
            } else {
                changeObj = shelf[module]
            }
            // console.log(moduleObjs);
        } else if (equipmentType == 4) {
            if (module == 'A1') {
                changeObj = injectBot[number - 1]
            } else if (module == 'B1') {
                changeObj = peristalticBot[number - 1]
            } else if (module == 'C') {
                changeObj = reaction
                isObjArr = true
            }

        }

        if (changeObj) {
            if (isObjArr) {
                changeObj.forEach(item => handleColor(item, color))
            } else {
                handleColor(changeObj, color)
            }
        }
    } catch (error) {
        console.log(error)
    }
}

const modelMove = (dire) => {
    planeObj.position.x = dire == 'left' ? -5 : 0
}
const handleColor = (changeObj, color) => {
    if (!changeObj) {
        return
    }
    if (color == 'origin') {

        changeObj.traverse(child =>
            child?.geometry && child?.originMaterial && (child.material = child?.originMaterial?.clone())
        )
        changeObj['lastColor'] = null
        changeObj['notChange'] = false
    } else {
        let colorObj = new THREE.Color(color)
        changeObj.traverse(child => {
            // child.geometry && child.material.color.set(colorObj)
            if (child.geometry) {
                child.material.color?.set(colorObj)
                child.material?.emissive?.set(colorObj)
            }
        })
        changeObj['lastColor'] = color
        changeObj['notChange'] = true
    }
}
/**
 * 
 * @param {Number} equipmentType 设备类型
 * @param {String} module 区块类型
 * @param {Number} number 瓶子编号
 */
const botPutUp = (config) => {
    try {
        const { equipmentType, module, number } = config
        // console.log(moduleObjs, equipmentType, module, number);

        if (equipmentType == 7 && moduleObjs && moduleObjs[module] && moduleObjs[module][number - 1]) {
            let bot = moduleObjs[module][number - 1]
            bot.position.z = 20
            if (module == 'J' || module == 'L') {
                bot.position.z = 36
            }

        }
    } catch (error) {
        console.log(error)

    }

}

const botPutBack = (config) => {
    try {
        const { equipmentType, module, number } = config
        // console.log(moduleObjs, equipmentType, module, number);

        if (equipmentType == 7 && moduleObjs && moduleObjs[module] && moduleObjs[module][number - 1]) {
            let bot = moduleObjs[module][number - 1]
            bot.position.z = 0
            if (module == 'J' || module == 'L') {
                // bot.position.z = 16
            }

        }
    } catch (error) {
        console.log(error)

    }

}

/**
 * 
 * @param {Number} equipmentType 设备类型 
 * @param {String} module 区块类型
 * @param {Number} number 瓶子编号
 * @param {String} content 内容
 * @param {String} show 是否显示
 * 
 * 
 */
const setLabelConent = (config) => {
    try {
        const { equipmentType, module, number, content, show } = config
        if (equipmentType == 7 && moduleObjs) {
            let changeObj = moduleObjs[module][number - 1].children.at(-1)
            content !== undefined && (changeObj.element.innerText = content)
            if (show) {
                changeObj.layers.mask = 1
                changeObj.show = true
            } else {
                changeObj.layers.mask = 0
                changeObj.show = false
            }
        }
    } catch (error) {
        console.log(error)
    }

}


const moduleHighlight = (equipmentType, module) => {
    try {
        if (equipmentType === '3') {
            // console.log(shelf);
            // let moduleArr = module.split()
            //  let moduleName = ${moduleArr[0]}-1
            shelf[module]['notChange'] = true
            shelf[module].traverse(child => {
                child.material = opacityMaterial.clone()
            })
        }
    } catch (error) {
        console.log(error)

    }

}

const moveBot = (config) => {
    try {
        const { equipmentType, bot, position } = config
        // console.log(equipmentType, bot, position);
        if (equipmentType == 7 && moduleObjs) {
            botInit({ botObjs: moduleObjs, bot, position })
        }
    } catch (error) {
        console.log(error)
    }
}

const botInit = (config) => {
    try {
        const { botObjs, bot, position } = config
        // console.log(config);
        if (!(botObjs && bot.module && bot.number && position.module && position.number)) {
            return
        }
        let originObj = moduleAxisMap[bot.module]
        const { x, y, col, betweenX, betweenY } = moduleAxisMap[position.module]
        const { offsetX, offsetY } = originObj
        let number = position.number * 1
        let yPoint = Math.ceil(number / col) - 1
        let xPoint = number % col == 0 ? col - 1 : number % col - 1

        let oNumber = bot.number * 1
        let oYPoint = Math.ceil(oNumber / originObj.col) - 1
        let oXPoint = oNumber % originObj.col == 0 ? originObj.col - 1 : oNumber % originObj.col - 1
        let changeObj = botObjs[bot.module][bot.number - 1]
        if (changeObj && changeObj) {
            let dx = offsetX - oXPoint * originObj.betweenX
            let dy = offsetY - oYPoint * originObj.betweenY
            if ((bot.module == 'J' || bot.module == 'L') && position.module !== 'J' && position.module !== 'L') {
                changeObj.position.z = 16
            }
            if (position.module == 'grab') {

                changeObj.position.z = 90

            }
            if (position.module == 'tip') {
                // console.log("💕", x, betweenX, xPoint, dx, config);
                changeObj.position.z = 129

            }
            changeObj.position.x = x + betweenX * xPoint + dx
            changeObj.position.y = y + betweenY * yPoint + dy

        }

    } catch (error) {
        console.log(error)

    }

}

const tipsInit = () => { }

export { opacityMaterial, noModules, setColor, botPutUp, botPutBack, moduleHighlight, moveBot, setLabelConent, botInit, tipsInit, modelMove }