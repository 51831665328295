import request from "./modules/request.js";

// 删除配方
export function deleteFormula(data) {
  return request({
    url: "/formula/basis-info/delete",
    method: "post",
    params: data,
  });
}

// 开始合成-校验合成条件
export function checkPreconditions(data) {
  return request({
    url: "/formula/basis-info/checkPreconditions",
    method: "post",
    params: data,
  });
}

// 新建/编辑配方
export function newFormula(data) {
  return request({
    url: "/formula/basis-info/saveUpdate",
    method: "post",
    data: data,
  });
}

// 下载配方
export function downloadFormula(data) {
  return request({
    url: "/formula/basis-info/exportBasis",
    method: "post",
    params: data,
    responseType: "blob",
  });
}

// 获取配方库列表/分页查询
export function getFormulaList(data) {
  return request({
    url: "/formula/basis-info/getBasisInfoPage",
    method: "post",
    data: data,
  });
}

// 立即生效的操作
export function immediateExecute(data) {
  return request({
    url: "/task/formula-device-execute/immediateExecute",
    method: "post",
    data,
  });
}
// 立即查询的操作
export function readCommandAndPushLog(data) {
  return request({
    url: "/hardware/test/readCommandAndPushLog",
    method: "post",
    data,
  });
}

// 立即生效的操作
export function updateByBlockId(data) {
  return request({
    url: "/formula/basis-form/updateByBlockId",
    method: "post",
    data: data,
  });
}
// 获取模块放回的区域
export function getPutDownModule(data) {
  return request({
    url: "/formula/basis-info/getPutDownModule",
    method: "post",
    params: data,
  });
}

// 获取模块的表单json
export function getByModuleId(data) {
  return request({
    url: "/formula/basis-form/getByModuleId",
    method: "get",
    params: data,
  });
}

// 保存模块的表单json
export function updateByFormId(data) {
  return request({
    // isUploadFile: true,
    url: "/formula/basis-form/updateByFormId",
    method: "post",
    data,
  });
}

// 保存模块的表单json的列表
export function listByFormId(data) {
  return request({
    url: "/formula/basis-form/list",
    method: "post",
    data,
  });
}

// 获取连续流进行中日志
export function getPushedLog(data) {
  return request({
    url: "/common/basis-experiment-log/getPushedLog",
    method: "post",
    data,
  });
}

// 获取连续流完成后的日志
export function getExperimentLog(data) {
  return request({
    url: "/common/basis-experiment-log/getExperimentLog",
    method: "post",
    data,
  });
}
// 获取连续流完成后的设备参数
export function experimentParam(data) {
  return request({
    url: "/common/basis-experiment-log/experimentParam",
    method: "post",
    data,
  });
}

// 查询配方的区块容器
export function getBlockItemList(data) {
  return request({
    url: "/formula/block-item/getBlockItemList",
    method: "post",
    data,
  });
}

// 查询点位信息
export function selectList(data) {
  return request({
    url: "/formula/basis-block/selectList",
    method: "post",
    data,
  });
}

// 查询配方可用加热通道
export function getUsableChannel(data) {
  return request({
    url: "/formula/block-item/getUsableChannel",
    method: "post",
    data,
  });
}
// 查询配方所有区块药品信息
export function getHeatUsableChannel(data) {
  return request({
    url: "/formula/block-item/getHeatUsableChannel",
    method: "post",
    data,
  });
}
// 查询配方所有区块药品信息
export function selectBlockMaterial(data) {
  return request({
    url: "/formula/basis-block/selectBlockMaterial",
    method: "post",
    data,
  });
}

// 查询配方注液可用点位
export function getUsableBlockForInject(data) {
  return request({
    url: "/formula/basis-block/getUsableBlockForInject",
    method: "post",
    data,
  });
}

// 查询配方注液可用点位
export function getUsableVesselList(data) {
  return request({
    url: "formula/block-item/getUsableVesselList",
    method: "post",
    data,
  });
}

//配方上传
export function importBasis(params, data) {
  return request({
    isUploadFile: true,
    url: "/formula/basis-info/importBasisResultData",
    method: "post",
    params: params,
    data: data,
  });
}
// 输入DOI获取论文methods
export function adaptiveInput(data) {
  return request({
    url: "/formula/basis-info/adaptiveInput",
    method: "post",
    data,
  });
}

// 输入DOI获取论文methods
export function methodToRecipe(params) {
  return request({
    url: "/formula/basis-info/methodToRecipe",
    method: "get",
    params: params,
  });
}

// 输入DOI确认生成配方
export function confirmGenerate(params) {
  return request({
    url: "/formula/basis-info/confirmGenerate",
    method: "get",
    params: params,
  });
}

// 取消生成配方
export function cancelGenerate(params) {
  return request({
    url: "/formula/basis-info/cancelGenerate",
    method: "get",
    params: params,
  });
}

// 获取生成配方记录
export function getAiGenerateRecord(params) {
  return request({
    url: "/formula/basis-info/getAiGenerateRecord",
    method: "get",
    params: params,
  });
}

// 搜索香型
export function searchFormal(params) {
  return request({
    url: "/formula/basis-info/searchPerfume",
    method: "post",
    params,
  });
}

// 开始香水配方
export function startPerfumeProccess(params) {
  return request({
    url: "/task/formula-device-execute/startPerfumeProccess",
    method: "post",

    data: params,
  });
}
