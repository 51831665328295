import { useState, useEffect, useContext, Fragment } from "react";
import { Button, Row, Col, message } from "antd";
import { nanoid } from "nanoid";
import { listFindItem } from "~/utils/jstools";
import {
  PluginContext,
  CommandContext,
  GlobalDataContext,
} from "../../../utils/reactContext.js";
import "../publicStyle.scss";

import eventBus from "~/utils/hooks/eventBus";

export default function StartAgitationB({ msg, content }) {
  const globalData = useContext(GlobalDataContext);
  const [actionJsonData, setActionJsonData] = useState({});
  useEffect(() => {
    // console.log("🚀 ~ globalData:", globalData, content);
    setActionJsonData(JSON.parse(content || "{}"));
  }, [content]);
  let data = {
    functionCode: "moveLiquidB",
    paramArr: [
      {
        blockType: "C",
        rotationRate: "100",
        startAgitationBExecuteTime: "10",
      },
    ],
  };

  if (!globalData?.allTaskTypeListZZT2 || !globalData?.blockTypeListZZT2)
    return <span className="textVal">请进入配方配置页面，进行此操作。</span>;
  const applyAction = () => {
    if (
      eventBus.eventList["AISubmitActionZZT2"] &&
      eventBus.eventList["AISubmitActionZZT2"].length > 0
    ) {
      eventBus.$emit("AISubmitActionZZT2", actionJsonData);
    } else {
      message.error("该操作执行失败");
    }
    // console.log("🚀 ~ applyAction ~ data:", eventBus.eventList);
  };
  return (
    <div className="aiActionView">
      <div className="treeColBox">
        <div className="taskInfo">
          <ul className="paramList">
            <Row>
              <Col span={23}>
                <span className="textVal">开始震荡</span>
              </Col>

              {actionJsonData?.paramArr?.length > 0 &&
                actionJsonData?.paramArr.map((item) => {
                  return (
                    <Fragment>
                      {Object.keys(item).map((param, index) => {
                        // console.log(
                        //   "🚀 ~ {Object.keys ~ param:",
                        //   param,
                        //   globalData?.allTaskTypeParamListZZT2[param]
                        // );
                        let paramName = "";
                        let paramValue = "";
                        let paramUnit = "";
                        if (globalData?.allTaskTypeParamListZZT2[param]) {
                          paramName =
                            globalData?.allTaskTypeParamListZZT2[param]
                              ?.frontShow;
                          paramValue = item[param];

                          paramUnit =
                            globalData?.allTaskTypeParamListZZT2[param]?.unit;
                        }
                        if (param.includes("blockType")) {
                          paramName = "区块类型";
                          paramValue =
                            globalData?.blockTypeListZZT2[item[param]];
                        }
                        return (
                          <Col span={12} key={nanoid()}>
                            <li style={{ display: "flex" }}>
                              <span className="frontShow">{paramName}:</span>
                              <span className="textVal">{paramValue}</span>
                              {paramUnit && <span>{paramUnit}</span>}
                            </li>
                          </Col>
                        );
                      })}
                    </Fragment>
                  );
                })}
            </Row>
          </ul>
        </div>
      </div>
      <div className="posBtn">
        <Button type="primary" onClick={applyAction}>
          应用
        </Button>
      </div>
    </div>
  );
}
