import { useState, useEffect, useRef } from "react";

import { Spin, message } from "antd";
import i18n from "i18next";
import { DialogAvatarSDK } from "~/utils/aliyun-avatar-sdk/dist/index.js";
import { startInstance, sendText, stopInstance } from "~/api/common";
import { generateUUID } from "~/utils/jstools";
import "./aliAvatar.scss";
import { AudioInit, AudioClose } from "./utils/xunfei-STT";
import eventBus from "~/utils/hooks/eventBus";
import { useNavigate, useLocation } from "react-router-dom";
let chromaKey = false; // 是否开启抠绿幕
let girl = null;

function extractChineseCharacters(str) {
  return str.match(/[\u4e00-\u9fa5]+/g) || [];
}
export default function AliAvatar() {
  const location = useLocation();
  let [digitalGirl, setDigitalGirl] = useState();
  let [isInit, setIsInit] = useState(false);
  const videoBox = useRef(null);
  const [isMute, setIsMute] = useState(true); // 是否静音
  // 数字人初始化
  function initAvatar(data) {
    // console.log(
    //   "girlRef.current",
    //   videoBox?.current,
    //   DialogAvatarSDK.canUseChromaKey()
    // );
    // if (!girlRef?.current) return;
    girl = new DialogAvatarSDK({
      // im连接参数
      tenantId: "26539",
      appId: "3dt1ed7769",
      sessionId: data?.sessionId,
      token: data?.token,
      channel: {
        userId: data?.channel?.userId,
        channelId: data?.channel?.channelId,
        appId: data?.channel?.appId,
        nonce: data?.channel?.nonce,
        expiredTime: data?.channel?.expiredTime,
        gslb: data?.channel?.gslb,
        token: data?.channel?.token,
      },
      options: {
        //   wsUrl: "wss://avatar-im.console.aliyun.com/ws", // 建立IM连接的websocket地址
        maxReconnectTimeout: 300000, // 断线重连最大尝试时间(ms)，超出这个时间仍未重连成功将抛出断线重连超时错误
        audio: {
          autoStartRecord: false, // 是否在初始化过程中自动开启浏览器录音
          interval: 100, // 音频采集间隔(ms)，注意不能小于60ms，不能大于1000ms，否则会抛出错误
          autoDodge: false, // 是否开启音频闪避
        },
        rtc: {
          muted: false, // 是否静音订阅流
          chromaKey: DialogAvatarSDK.canUseChromaKey() || false, // 如果需要开启抠绿幕，请解注释这行代码
        },
      },

      videoDOM: videoBox?.current,
      onInitSuccess: () => {
        console.log("IM连接成功，RTC拉流成功", girl);
      },
      onError: (e) => {
        console.log("初始失败", e);
      },
      onRecordingStart: () => {
        console.log("录音中");
      },
      // 收音结束回调
      onRecordingStop: () => {
        // 可以在ui上提示用户
        console.log("未录音");
      },
      onASR: (text, sentenceId) => {
        /**一个句子识别结束 */
        console.log("录音识别结果", text, sentenceId);
      },
    });
    return girl.init();
  }
  const [authData, setAuthData] = useState();
  const start = () => {
    startInstance().then(async (res) => {
      // console.log(res);
      if (res.code == 0) {
        setAuthData(res.data);
        initAvatar(res.data).then((obj) => {
          // console.log("🚀 ~ 数字人初始化完毕 ~ res:", obj, girl);
          // eventBus.$emit("avatarSpeech", "在呢！");
        });
      }
    });
  };

  useEffect(() => {
    eventBus.$on("avatarSpeech", (text) => {
      // console.log("🚀 ~ useEffect ~ text:", text);
      if (!isMute) speech(text);
    });

    eventBus.$on("STT_MODE", (mode) => {
      // console.log("🚀 ~ file: index.jsx:38 ~ mode:", mode);
      if (mode == "voice") {
        startAudio();
      } else if (mode == "baibaixiaokui") {
        stop();
      } else {
        AudioClose();
      }
    });
    return () => {
      eventBus.$off("avatarSpeech");
      eventBus.$off("STT_MODE");
      // console.log("🚀 ~ return ~ girl:", girl);
    };
  }, [authData, isMute]);

  const speech = (text) => {
    // console.log("🚀 ~ speech 小葵说话:", text, authData, girl);
    if (!authData?.sessionId) return;
    sendText({
      sessionId: authData?.sessionId,
      text: text,
      interrupt: false,
      uniqueCode: generateUUID(),
    }).then((res) => {
      if (res.code != 0) {
        console.log(res);
      }
    });
  };
  const stop = () => {
    // console.log("🚀 ~ stop 小葵停止:", authData);
    if (!authData?.sessionId) return;
    stopInstance({ sessiondId: authData?.sessionId }).then((res) => {
      if (res.code == 0) {
        setAuthData(null);
        message.success("关闭成功");
        if (girl?.name) girl.destory();
      }
    });
  };

  const navigate = useNavigate();
  const goHome = () => {
    navigate("/");
  };
  const goFormulaList = () => {
    navigate("/formulaList");
  };
  const [isRecord, setIsRecord] = useState(false);
  const [isLive, setIsLive] = useState(false);
  useEffect(() => {
    // console.log("🚀 ~ 是否静音:", isLive ? "是" : "否", girl);
    // 开启收音时，关闭音频闪避

    if (!girl?.name) return;
    if (isLive) {
      /* global dialogAvatarSDK */
      girl.muteRtc();
    } else {
      girl.unMuteRtc();
    }
  }, [isLive]);
  const startAudio = () => {
    // console.log("🚀 ~ 语音开启状态:", isRecord, isLive);

    if (isRecord && isLive) {
      AudioClose();
      setIsRecord(false);
      setIsLive(false);
      eventBus.$emit("AIStatus", { isRecording: false });
      return;
    }
    setIsRecord(true);
    eventBus.$emit("AIStatus", { isRecording: true });
    if (!isRecord && !isLive) {
      AudioInit((res) => {
        // console.log("语音识别出的文字:", res);
        if (res.code == "100") {
          // 开启语音
          setIsRecord(true);
          setIsLive(true);
          eventBus.$emit("AIStatus", { isRecording: true });
          return;
        } else if (res.code == "0") {
          console.log("语音识别出的文字", res.msg);
          // 识别出的语言
          // let text = extractChineseCharacters(res.msg)[0];
          instruct(res.msg);
        } else {
          // 关闭语音
          setIsRecord(false);
          setIsLive(false);
          eventBus.$emit("AIStatus", { isRecording: false });
        }
      });
    }
  };
  const enterDevice = (text) => {
    eventBus.$emit("enterDevice", text);
  };
  const enterFormula = (text) => {
    eventBus.$emit("enterFormula", text);
  };
  const instruct = (text) => {
    eventBus.$emit("RecordText", text);
    // console.log("🚀 ~ instruct ~ text:", text, "小奎");
    switch (text) {
      case "小葵小葵":
        start();
        break;
      case "小葵再见":
        speech("好的，随时呼唤我");
        stop();
        break;
      case "打开设备列表":
        speech("好的，已打开设备列表");
        goHome();
        break;
      case "返回首页":
        speech("好的，已打开首页");
        goHome();
        break;
      case "开始实验":
        speech("好的，马上开始");
        eventBus.$emit("controlZZTB", "开始实验");
        break;
      case "继续实验":
        speech("好的，马上继续");
        eventBus.$emit("controlZZTB", "继续实验");
        break;
      case "暂停实验":
        speech("好的，马上暂停");
        eventBus.$emit("controlZZTB", "暂停实验");
        break;
      case "开启播报":
        speech("好的，马上开启");
        setIsMute(false);
        break;
      case "暂停播报":
        speech("好的，马上暂停");
        setIsMute(true);
        break;

      case "结束实验":
        speech("好的，马上结束");
        eventBus.$emit("controlZZTB", "结束实验");
        break;

      case "进入实验室模式":
        speech("好的，已进入");
        eventBus.$emit("roomModeZZTB", "room");
        break;
      case "返回实验界面":
        speech("好的，已返回");
        eventBus.$emit("roomModeZZTB", "single");
        break;
      case "返回配方列表":
        speech("好的，已返回");
        goFormulaList();
        break;
      default:
        if (/进入设备/.test(text)) {
          speech("好的，已进入");
          enterDevice(text);
        }
        if (/进入配方/.test(text)) {
          speech("好的，已进入");
          enterFormula(text);
        }
        break;
    }
  };
  return (
    <div
      className={`avatarBox ${
        authData?.sessionId && location?.pathname !== "/login"
          ? "initAvatar"
          : ""
      }`}
      //   onClick={() => setIsInit(!isInit)}
    >
      {isInit && (
        <div className="avatarHd">
          <button onClick={start}>开启数字人</button>
          <button onClick={speech}>说话</button>
          <button onClick={() => instruct("小葵小葵")}>小葵小葵</button>
          <button onClick={() => instruct("小葵再见")}>小葵再见</button>
          <button onClick={() => instruct("打开设备列表")}>打开设备列表</button>
          <button onClick={() => instruct("返回首页")}>返回首页</button>
          <button onClick={() => instruct("进入设备异木棉ht02")}>
            进入设备异木棉ht02
          </button>
          <button onClick={() => instruct("进入配方测试光谱")}>
            进入配方测试光谱
          </button>
          <button onClick={() => instruct("进入实验室模式")}>
            进入实验室模式
          </button>
          <button onClick={() => instruct("返回实验界面")}>返回实验界面</button>
          <button onClick={() => instruct("返回配方列表")}>返回配方列表</button>

          <button onClick={() => instruct("开始实验")}>开始实验</button>
          <button onClick={() => instruct("继续实验")}>继续实验</button>
          <button onClick={() => instruct("暂停实验")}>暂停实验</button>
          <button onClick={() => instruct("结束实验")}>结束实验</button>

          <button onClick={() => startAudio()}>开启语音识别</button>
        </div>
      )}
      {isLive && <div className="recordStatus"></div>}
      <div onClick={() => startAudio()} className="avatarBd">
        <video
          ref={videoBox}
          webkit-playsinline="true"
          x5-playsinline="true"
          x-webkit-airplay="allow"
          playsInline={true}
          className={authData?.sessionId ? "active" : ""}
        ></video>
      </div>
    </div>
  );
}
