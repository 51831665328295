// TODO: 修改键时，需要全局搜索，注意多处使用同一个键。
export default {
    //登录
    "账号密码登录": "Account login",
    "手机号码登录": "Phone number login",
    "请输入用户名": "Please enter username",
    "请输入密码": "Please enter password",
    "登录": "Login",
    "重置": "Reset ",
    "还没有账号，去": "Don't have an account yet. ",
    "注册": "Register",
    "登录成功": "Login successful",
    //个人资料
    "用户名": "username",
    "个人资料": "Personal data",
    "修改密码": "Change password",
    "修改手机号": "Change phone number",
    "用户管理": "User management",
    "退出登录": "Log out",
    //原料库配置
    "原料库配置": "Raw Materials Database",
    //原料查询
    "原料名称": "raw materials name",
    "CAS编号": "CAS number",
    "描述": "description",
    "查询": "search",
    "返回": "back",
    //原料列表
    "CAS编号/原料编号": "CAS number/raw materials number",
    "光谱图": "spectrogram",
    "操作": "Operations",
    "编辑": "Edit",
    "新增": "New",
    "删除": "Delete",
    //模拟
    "上一步": "Previous step",
    "开始模拟": "Start simulation",
    "结束": "End",
    "暂停": "Pause",
    "树根图": "ROOT",
    "动作列表": "motion list",
    "实验进度": "Experimental progress",
    "动作参数": "motion parameters",
    "反应器参数": "Reactor parameters",

    //合成中初始化
    "原料装载": "Raw material loading",
    "硬件查验和环境设置": "Hardware check and environment setting",
    "正常": "normal ",
    "异常": "abnormal",
    "移液模组": "pipette module",
    "氮吹模块": "nitrogen blowing module",
    "振摇模块": "vibratiom module",
    "设备初始化": "Device initialization",
    "设备初始化日志": "Device initialization log",
    "原料清单": "Raw material list",
    "瓶中仅允许添加液体原料，否则会损坏机器。": "Only liquid raw materials are allowed in the bottle, otherwise it will damage the machine.",
    "清单列表": "List",
    "编号": "Number",
    "原料名称": "material name",
    "容量": "volume",
    "操作": "operation ",
    "确认": "Confirm",
    "确认此容量的原料装配成功": "please confirm the loading of this volume of raw materials.",
    "取消": "Cancel",
    "确定": "Confirm",
    "原料确认": "Yes",
    "原料确认成功": "Raw Material Confirmation Success",

    //合成中数字孪生
    "实验进度": "Experimental progress",
    "日志": "Journal",
    "机器参数": "Machine parameters",
    "模块名称": "module name",
    "设定值": "set value",
    "当前值": "current value",
    "通道": "Channels",
    "通道1": "Channels 1",
    "通道2": "Channels 2",
    "通道3": "Channels 3",
    "通道4": "Channels 4",
    "通道5": "Channels 5",
    "通道6": "Channels 6",
    "振荡模块": "vibration module",
    "已连接": "connected",
    "未连接": "disconnected",
    //成分占比
    "成分占比": "Composition ratio",
    "反应模块": "reaction module",
    "反应模块1": "reaction module 1",
    "反应模块2": "reaction module 2",
    "无原料": "no raw material",
    "原料信息": "raw material information",
    "全部下载": "Download All",
    "无": 'none',
    //拉曼光谱检测
    "拉曼光谱检测": "Raman spectrum",
    "实验时间": "detection experiment time",
    "波数": "wave number",
    "强度": "intensity",
    //原料使用情况
    "原料使用情况": "Use of raw materials",
    "区块类型": "Block type",
    "瓶子序号": "Bottle serial number",
    "en号": "No.",
    "号瓶子": "bottle",
    "药品名称": "Drug name",
    "剩余容量": "Residual volume",
    "使用量": "Usage amount",
    //实验播报
    "实验播报": "Experiment broadcast",
    //合成中导航栏
    "单设备模式": "Single Device Mode",
    "关闭监控画面": "Close Monitor Screen",

    "监控画面": "Monitor screen",
    "实验室模式": "lab mode",
    "开始": "start",
    "暂停": "pause",
    "结束": "end",
    "首页": "home page",
    "继续": "continue",
    "配方列表": "recipe list",
    "流程配置": "folw configuration",
    "下一步": "Next step",
    "配方合成(异木棉HT01)": 'recipe synthesis(Isokapok HT01)',
    "初始化": "Initialization",
    "合成中": "synthesizing",
    "实验已结束": "ended",
    "配方合成": "recipe synthesis",
    "流程开始": "start the flow",
    "流程继续": "continue the flow",
    "流程已结束": "flow ended",
    "流程暂停": "pause the flow",

    //合成中树根图
    "树根图": "ROOT",
    "当前时间切片": "Current time slice",
    "瓶子信息": "Bottle information",
    "持续时间": "Duration",
    "运行时间": "Running time",
    "总运行时间": "Total running time",
    "液体信息": "Liquid information",
    "瓶内液体": "In-bottle liquid",
    "动作": "Actions",
    "参数": "Parameters",
    "图例": "legend",
    "序号": "number",
    "颜色及含义": "colors and meaning",
    "步骤时间及其他信息": "Step time and other information",
    "步骤操作": "Step operation",
    "模块信息": "Module information",
    "表征数据": "Characterization data",
    // 设备列表页面
    '工作站': 'WorkStation',
    '异木棉MC01': 'Isokapok MC01',
    '高通量高频药化反应工作站': 'High throughput high frequency drug reaction workstation',
    '异木棉HT01': 'Isokapok HT01',
    '高通量水热合成工作站': 'High flux hydrothermal synthesis workstation',
    '异木棉PC01': 'Isokapok PC01',
    '高通量光催化评价工作站': 'High throughput photocatalytic evaluation workstation',
    '异木棉MC02': 'Isokapok MC02',
    '高通量高频药化反应工作站': 'High throughput high frequency drug reaction workstation',
    '异木棉HT02': 'Isokapok HT02',
    '高通量水热合成工作站': 'High flux hydrothermal synthesis workstation',
    '木棉FS01': 'Kapok FS01',
    '模块化连续流合成工作站': 'Modular continuous flow workstation',
    '木棉QD01': 'Kapok QD01',
    '量子点连续合成工作站': 'quantum dot continuous synthesis workstation',
    '空闲中': 'free',
    '运行中': 'Running',
    // 配方列表页面
    '返回': 'back',
    '全部配方': 'All recipes',
    '全部分类': 'Recipe classification',
    '摘要': 'Abstract',
    '时间': 'time',
    '开始': 'Start',
    '配置': 'Config',
    '详情': 'Details',
    '删除': 'Delete',
    '新建配方': 'New recipe',
    '上传配方': 'Upload recipe',
    '手动创建配方': 'Manually create the recipe',
    '配方分类': 'recipe classification',
    '配方名称': 'Recipe name',
    'AI创建配方': 'AI creates recipe',
    '输入DOI/输入内容': 'Enter DOI/ Enter content',
    "请输入配方名称": "Please enter recipe name",
    '上传配方': 'Upload recipe',
    '关闭': 'Close',
    '提交': 'Submit',
    '请输入配方的摘要/简介': 'Please enter the summary/abstract of the recipe',
    '请输入摘要!': 'Please enter the summary!',
    '请输入配方名称': 'Please enter the recipe name',
    '请选择分类': 'Please select the classification',
    //上传配方
    '配方详情': "Recipe details",
    '上传配方': "Upload recipe",
    '点击上传配方文件': "Click to upload recipe file",
    '配方异常日志': "Recipe exception log",
    '关闭': "Close",
    '实验详情': "Experiment details",
    //配方详情
    "配方详情": "Recipe details",
    '配方分类': "Recipe classification",
    '配方名称': "recipe name",
    '摘要': "abstract",
    '编辑配方': "Edit recipe",
    '合成记录': "Composite records",
    '批量下载': "Batch download",
    '实验名称': "Experiment name",
    '名称': 'name',
    '描述': "Description",
    '开始时间': "Start time",
    '结束时间': "End time",
    '状态': "Status",
    '运行中': "Running",
    '手动结束': "Manual end",
    '异常结束': "abnormal end",
    '正常结束': "Normal end",
    '操作': "Operations",
    '下载配方文件': "Download recipe files",
    '实验回放': "experiment playback",
    '下载监控': "download monitoring",
    '更多': "more",
    '删除': "Delete",
    '编辑': "edit",
    '查看日志': "view logs",
    //软件翻译
    "输入文本查询合成记录": "Enter words and search synthesis records",
    '语音控制': 'Voice control',
    '语音识别中': 'Voice recognition',
    //回放
    '播放': 'Play',
    '暂停': 'Pause',
    //弹框，提示之类的
    '请确认是否删除此配方': 'please confirm whether delete the chosen recipe',
    '此操作不可逆': 'which is irreversible',
    '配方生成失败，请重试！': 'The recipe generation failed, please try again!',
    '前往编辑': 'Go to edit',
    '取消编辑': 'Canceledit',
    '生成成功': 'Generate success',
    '确认生成': 'Confirm generate',
    '取消生成': 'Cancel generate',
    '提示': 'Prompt',
    '未知': 'Unknown',
    '异常': 'Exception',
    '登录成功': 'Login successfully',
    '成功': 'Success',
    '异常信息': 'Exception information',
    '异常日志': 'Exception log',
    '序号': 'Id',
    '配方加密': 'Recipe encryption',
    '设置密码': 'Set password',
    '直接下载': 'Download directly',
    '有内容未保存，确认切换到其他页面吗?': 'There is content not saved. Are you sure you want to switch to another page?',
    'AI配方生成中，请耐心等待': 'Recipe generating by AI now, please wait patiently.',
    '配方存在需手动操作的步骤，是否继续生成。': 'The recipe has manually operate steps, whether to continue generating.',
    '删除配方成功': 'Recipe delete successfully',
    '此操作不可撤回': 'This operation cannot be reversed',
    '不能为空!': 'Can not be empty!',
    '操作名称': 'Operation name',
    '详情信息': 'Details',
    '操作状态': 'Operation status',
    '日志详情': 'Log details',

    // 通用配置
    '幻爽实验设备控制系统': 'Fine-Fanta Experiment Control System',

    // 输入值的验证
    '设置6至10位任意字符': 'Set 6 to 10 characters',

};