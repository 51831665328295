import { useNavigate, useLocation } from "react-router-dom";

const useCommands = () => {
  const navigate = useNavigate();

  // 返回首页
  const goHome = () => navigate("/");

  //   进入设备列表
  const goDeviceList = (params) => navigate("/formulaList");

  return {
    goHome,
    goDeviceList,
  };
};
export default useCommands;
