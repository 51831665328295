import eventBus from "~/utils/hooks/eventBus";
import { getSearch } from "~/utils/jstools";
import { functionListCom } from "~/api/common.js";
import { selectBlockMaterial } from "~/api/formula-basis";
import { message } from "antd";

// 字典格式化 - 解析动作参数用
const formatDict = (list) => {
  let addParamMap = {
    blockType: {
      frontShow: "区块类型",
      paranName: "blockType",
    },
  };
  let dictList = { ...addParamMap };
  let allActionList = {};
  if (!list) return;
  list.forEach((item) => {
    const actionList = item?.basisFunctionModellList || [];

    // console.log("🚀 ~ file: index.jsx:83 ~ formatDict ~ actionList:", actionList)
    actionList.forEach((action) => {
      const params = action?.basisFunctionParamModellList || [];
      allActionList[action?.functionCode] = action;
      // console.log("🚀 ~ file: index.jsx:86 ~ formatDict ~ params:", params)
      params.forEach((param) => {
        dictList[param.paranName] = param;
      });
    });
  });
  eventBus.$emit("AIGlobalData", {
    allTaskTypeListZZT2: allActionList,
    allTaskTypeParamListZZT2: dictList,
  });
  // eventBus.$emit("AIGlobalData", { deviceInfoZZT2: deviceInfo });
};
// 获取区块类型
const getBlockTypeList = (data) => {
  // 如果瓶子列表为空，则清空默认值
  let allBlockType = {};

  data.forEach((item) => {
    // console.log("🚀 ~ file: index.jsx:208 ~ getDeviceInfoForModelForBottle ~ item:", item)
    // 处理所有的模块可选值
    allBlockType[item.blockType] = item.blockName;
  });
  // console.log("🚀 ~ getBlockTypeList ~ allBlockType:", allBlockType);
  eventBus.$emit("AIGlobalData", { blockTypeListZZT2: allBlockType });
};
export const getZZTBData = (data) => {
  let fId = getSearch(window.location.href)?.id;
  if (!fId) return;
  const params = {
    distinct: 1,
    formulaBasisId: getSearch(window.location.href)?.id,
  };
  functionListCom(params).then((res) => {
    // console.log("🚀 ~ file: index.jsx:65 ~ getProcessBasisConfigList ~ res:", res)
    if (res.code == "0") {
      formatDict(res.data);
    } else {
      message.warning(res.msg || "数据错误");
    }
  });
  selectBlockMaterial({
    formulaId: getSearch(window.location.href)?.id,
  }).then((res) => {
    // console.log("🚀 ~ file: multiDrawer.jsx:101 ~ getBottleList ~ res:", res)
    if (res.code == 0) {
      getBlockTypeList(res.data);
    } else {
      res.msg && message.error(res.msg);
    }
  });
};
