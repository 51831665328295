import { message } from "antd";
// 生成ws地址
function getWebSocketUrl(apiKey, apiSecret) {
  var url = "wss://iat-api.xfyun.cn/v2/iat";
  var host = "iat-api.xfyun.cn";
  var date = new Date().toGMTString();
  var algorithm = "hmac-sha256";
  var headers = "host date request-line";

  var signatureOrigin = `host: ${host}\ndate: ${date}\nGET /v2/iat HTTP/1.1`;
  var signatureSha = window.CryptoJS.HmacSHA256(signatureOrigin, apiSecret);
  var signature = window.CryptoJS.enc.Base64.stringify(signatureSha);
  var authorizationOrigin = `api_key="${apiKey}", algorithm="${algorithm}", headers="${headers}", signature="${signature}"`;
  var authorization = btoa(authorizationOrigin);
  url = `${url}?authorization=${authorization}&date=${date}&host=${host}`;
  return url;
}
function encodeText(text, type) {
  if (type === "unicode") {
    let buf = new ArrayBuffer(text.length * 4);
    let bufView = new Uint16Array(buf);
    for (let i = 0, strlen = text.length; i < strlen; i++) {
      bufView[i] = text.charCodeAt(i);
    }
    let binary = "";
    let bytes = new Uint8Array(buf);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  } else {
    return window.Base64.encode(text);
  }
}
function toBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

let iatWS;
let resultText = "";
let resultTextTemp = "";
let countdownInterval;
let recorder = null;

export function AudioInit(setText) {
  // 开始建立ws
  var APPID = "fa77847b";
  var API_SECRET = "NDgyY2UyZDc2YjYzN2EwY2UyYTU0NDI5";
  var API_KEY = "3a078b92d91984f32663176303f3d43a";
  if (!window.RecorderManager)
    return message.warning("非https的地址，不支持语音识别功能！");
  recorder = new window.RecorderManager(
    window.location.origin +
      process.env.REACT_APP_BASE_ORIGIN_PATH +
      "/threeParty/STT"
  );
  recorder.onStart = () => {
    start();
  };

  connectWebSocket();

  // 建立websocket连接
  function connectWebSocket() {
    const websocketUrl = getWebSocketUrl(API_KEY, API_SECRET);
    if ("WebSocket" in window) {
      iatWS = new WebSocket(websocketUrl);
    } else if ("MozWebSocket" in window) {
      iatWS = new window.MozWebSocket(websocketUrl);
    } else {
      alert("浏览器不支持WebSocket");
      return;
    }
    // changeBtnStatus("CONNECTING");
    iatWS.onopen = (e) => {
      setText({ code: 100, msg: "开始录音" });
      // changeBtnStatus("OPEN");
      // 开始录音
      recorder.start({
        sampleRate: 16000,
        frameSize: 1280,
      });
      var params = {
        common: {
          app_id: APPID,
        },
        business: {
          language: "zh_cn",
          domain: "iat",
          accent: "mandarin",
          vad_eos: 5000,
          ptt: 0,
          //   dwa: "wpgs",
        },
        data: {
          status: 0,
          format: "audio/L16;rate=16000",
          encoding: "raw",
        },
      };
      iatWS.send(JSON.stringify(params));
    };
    iatWS.onmessage = (e) => {
      // console.log("🚀 ~ 收音msg:", e);
      renderResult(e.data);
    };
    iatWS.onerror = (e) => {
      console.log("🚀 ~ 收音失败:", e);
      console.error(e);
      recorder.stop();

      setText({ code: -1, msg: "收音失败" });
      // changeBtnStatus("CLOSED");
    };
    iatWS.onclose = (e) => {
      recorder.stop();
      // setText({ code: 101, msg: '收音结束' })
      // changeBtnStatus("CLOSED");
    };
  }

  // 开启录音
  function start() {
    let seconds = 5;
    countdownInterval = setInterval(() => {
      seconds = seconds - 1;
      if (seconds <= 0) {
        clearInterval(countdownInterval);
        setText({ code: 101, msg: "收音结束" });
        recorder.stop();
      } else {
        // btnControl.innerText = `录音中（${seconds}s）`;
      }
    }, 1000);
  }
  recorder.onFrameRecorded = ({ isLastFrame, frameBuffer }) => {
    if (iatWS.readyState === iatWS.OPEN) {
      iatWS.send(
        JSON.stringify({
          data: {
            status: isLastFrame ? 2 : 1,
            format: "audio/L16;rate=16000",
            encoding: "raw",
            audio: toBase64(frameBuffer),
          },
        })
      );
      if (isLastFrame) {
        // changeBtnStatus("CLOSING");
      }
    }
  };
  recorder.onStop = () => {
    clearInterval(countdownInterval);
  };
  // 处理识别结果
  function renderResult(resultData) {
    // 识别结束
    let jsonData = JSON.parse(resultData);
    // console.log("🚀 ~ renderResult ~ jsonData:", jsonData);
    if (jsonData.data && jsonData.data.result) {
      let data = jsonData.data.result;
      let resultText = "";
      let ws = data.ws;
      for (let i = 0; i < ws.length; i++) {
        resultText = resultText + ws[i].cw[0].w;
      }
      // console.log('resultText:', resultText)
      // 开启wpgs会有此字段(前提：在控制台开通动态修正功能)
      // 取值为 "apd"时表示该片结果是追加到前面的最终结果；取值为"rpl" 时表示替换前面的部分结果，替换范围为rg字段
      // if (data.pgs) {
      //     if (data.pgs === "apd") {
      //         // 将resultTextTemp同步给resultText
      //         resultText = resultTextTemp;
      //     }
      //     // 将结果存储在resultTextTemp中
      //     resultTextTemp = resultText + str;
      // } else {
      //     resultText = resultText + str;
      // }
      // resolve(resultTextTemp || resultText || "")
      setText({ code: 0, msg: resultText });
    } else {
      // console.log("识别失败:", jsonData);
      setText({ code: -1, msg: "识别失败" });
    }
    if (jsonData.code === 0 && jsonData.data.status === 2) {
      setText({ code: 101, msg: "收音结束" });
      iatWS.close();
    }
    if (jsonData.code !== 0) {
      iatWS.close();
      setText({ code: 101, msg: "收音结束" });
      console.error(jsonData);
    }
  }
}
export function AudioClose() {
  console.log("🚀 ~ AudioClose ~ recorder:", recorder);
  clearInterval(countdownInterval);
  recorder && recorder.stop();
  iatWS && iatWS.close();
}
