
// 配方列表页引导配置
export const workFlowModel = (el) => {
    return [
        {
            title: <div>添加原料（<span style={{ color: "#1677ff" }}>必填项</span>）</div>,
            description: <div>下方模块名称为原料模块I，<span style={{ color: "#1677ff" }}>单击选择下方任意瓶子</span>进行实验原料的添加。</div>,
            target: () => el[0],
            blockName: 'C',
            blockType: 'xfz',
            // prevButtonProps: { style: { display: 'none' } },
            placement: 'top',
            step: 4,
        },

        {
            title: '配置实验温度（选填项）',
            description: <div>下方模块名称为反应模块I，<span style={{ color: "#1677ff" }}>单击瓶子</span>可提前在此步骤<span style={{ color: "#1677ff" }}>选择通道配置实验温度</span>。</div>,
            target: () => el[0],
            blockName: 'A',
            blockType: 'xfz',
            // prevButtonProps: { style: { display: 'none' } },
            placement: 'top',
            step: 5,
        },
        {
            title: '点击【下一步】',
            description: <div>初始条件设置完成后，点击【<span style={{ color: "#1677ff" }}>下一步</span>】进入流程的配置。</div>,
            target: () => el[1],
            // prevButtonProps: { style: { display: 'none' } },
            placement: 'top',
            step: 6,
        },
    ];
}
// 配方列表页引导配置
export const workFlowStep1Mater = (el) => {
    return [
        {},
        {

            title: '填写原料和容量',
            description: <div>选择实验的原料并填写适当的容量。<span style={{ color: "#1677ff" }}>全部填写完成后请点击【提交】按钮</span>。</div>,
            target: () => el[0],
            prevButtonProps: { style: { display: 'none' } },
            placement: 'left',
            step: 7,
        },

    ];
}
export const workFlowStep1Temp = (el) => {
    return [
        {},
        {

            title: '设置温度',
            description: <div>在右侧可选择加热或低温，并设置相应的温度。<span style={{ color: "#1677ff" }}>选择完成后请点击提交按钮</span>。</div>,
            target: () => el[0],
            prevButtonProps: { style: { display: 'none' } },
            placement: 'left',
            step: 8,
        },

    ];
}


// 配方列表页引导配置
export const workFlowStep2Model = (el) => {
    return [
        {
            title: '反应模块I',
            description: <div>此模块是添加条件发生反应的模块。具体操作包含<span style={{ color: "#1677ff" }}>吸液、注液、加热、低温、通氮、真空和震摇</span>操作。可点击瓶子选择操作。</div>,
            target: () => el[0],
            blockName: 'A',
            blockType: 'xfz',
            placement: 'top',
            step: 9,
        },
        {
            title: '反应模块II',
            description: <div>此模块是添加条件发生反应的模块。具体操作包含<span style={{ color: "#1677ff" }}>吸液、注液、加热、低温、通氮、真空和震摇</span>操作。可点击瓶子选择操作。</div>,
            target: () => el[0],
            blockName: 'B',
            blockType: 'xfz',
            placement: 'top',
            step: 10,
        },
        {
            title: '原料模块I',
            description: <div>原料模块内装有实验所需的原料，在该模块可进行<span style={{ color: "#1677ff" }}>吸液、放帽、锁帽</span>动作。</div>,
            target: () => el[0],
            blockName: 'C',
            blockType: 'xfz',
            placement: 'top',
            step: 11,
        },
        {
            title: '原料模块II',
            description: <div>原料模块内装有实验所需的原料，在该模块可进行<span style={{ color: "#1677ff" }}>吸液、放帽、锁帽</span>动作。</div >,
            target: () => el[0],
            blockName: 'C2',
            blockType: 'xfz',
            placement: 'top',
            step: 12,
        },
        {

            title: '产物模块',
            description: <div> 此模块可放置反应后的液体用于检测。可进行<span style={{ color: "#1677ff" }}>注液、真空、放帽和锁帽</span>操作。</div >,
            target: () => el[0],
            blockName: 'D',
            blockType: 'xfz',
            placement: 'top',
            step: 13,
        },
        {
            title: '前处理模块',
            description: <div > 此模块可放置放置在产物模块上封盖。可操作<span style={{ color: "#1677ff" }}>抓取、放下</span>。</div >,
            target: () => el[0],
            blockName: 'E',
            blockType: 'xfz',
            placement: 'top',
            step: 14,
        },
        {
            title: <div >点击【<span style={{ color: "#1677ff" }}>新增分组</span>】</div >,
            description: <div > 新建动作前必须要选择一个分组。</div >,
            target: () => el[1],
            placement: 'left',
            step: 15,
        },
    ];
}
