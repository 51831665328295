import React, { Fragment, useEffect, useRef, useState } from 'react'
import { BorderBox13 } from '@jiaminghi/data-view-react'
import { Space, Tag, Button, message } from 'antd'
import { nanoid } from 'nanoid'
import * as THREE from 'three'
import { mergeGeometries, mergeGroups } from 'three/examples/jsm/utils/BufferGeometryUtils.js'


import threeInit from '../../components/process/commonModel/threeInit'
import Pip from './module/pip'
import styles from './index.scss'
import { loadGLTFMod } from '../../components/process/commonModel/loadModel'
import { opacityMaterial } from '../equipment02/modules/modelOpera'
import { css2DLabel } from '../continuousFlowModel/modules/modelParam'
import { getSearch } from '~/utils/jstools.js'
import { getFunctionList } from '~/api/common'
import { setColor } from '../equipment02/modules/modelOpera'
import { iPipArr } from './module/hardwareConfig'


let originMaterial

let reaction, injectBot, peristalticBot, injectPump, peristalticPump

export default function QuantumDot(props) {
    const cfRef = useRef()
    //连续流threeInit对象
    // const [threeObj.current, setthreeObj.current] = useState(null)
    const threeObj = useRef()
    const [moduleName, setModuleName] = useState(null)
    const [modelLoaded, setModelLoaded] = useState(null)
    const moduleFunMap = useRef({
        "A1": {
            "name": "注射泵原料区",
            "fun": [
                "设置原料",
            ]
        },
        "B1": {
            "name": "蠕动泵原料区",
            "fun": [
                "设置原料",
            ]
        },

    })
    const settingModel = useRef()
    const labelDiv = useRef()
    // const shelf = useRef()
    const B1 = useRef()
    const A1 = useRef()

    //模型组
    useEffect(() => {
        if (threeObj.current) {
            threeObj.current.initQDCamera()
            threeObj.current.initQDLight()
            threeObj.current.initControls()
            threeObj.current.initRenderer()
            modelInit('quantumDot')
            threeObj.current.animate()
        }
    }, [threeObj.current])
    useEffect(() => {
        if (modelLoaded) {
            props.initModelLoaded()
        }
    }, [modelLoaded])


    useEffect(() => {
        try {
            getFunctionList({
                "formulaBasisId": getSearch(window.location.href)?.id
            }).then(res => {
                if (res.code == 0) {
                    // console.log(moduleFunMap.current);

                    for (let key in res.data) {
                        moduleFunMap.current[key] = { name: key, fun: [] }
                        res.data[key].forEach(item => {
                            moduleFunMap.current[key]['fun'].push(item.functionName)
                            moduleFunMap.current[key]['name'] = item.blockName
                        })
                    }
                    // console.log(moduleFunMap.current);
                } else message.error(res.msg)

            })
        } catch (error) {
            console.log(error)

        }

        return () => {
            let varArr = [originMaterial, reaction, injectBot, peristalticBot, injectPump, peristalticPump]
            varArr.forEach(item => { item = null })
            threeObj?.current?.scene?.traverse(obj => {
                if (obj.type === 'Mesh') {
                    obj.geometry.dispose();
                    obj.material.dispose();
                }
            })
            threeObj?.current?.current?.deleteEffect()
            threeObj?.current?.scene?.clear();
        }
    }, [])
    useEffect(() => {
        if (threeObj.current) {
            threeObj.current.onWindowResize()
            threeObj.current.renderer.sortObjects = true
            threeObj.current.renderer.shadowMap.enabled = true;
            // if (threeObj?.current?.scene?.add == undefined) return
            threeObj.current?.scene?.add(settingModel?.current)
        }
        // console.log(props.current);
        if (props.current == 2) {
            setColor({ equipmentType: '4', module: 'C1', color: 'origin' })
        }
        // threeObj.current?.scene?.remove(simuModel?.current)

    }, [props.current])

    const resizeUpdate = () => {
        threeObj.current && threeObj.current.onWindowResize()
    }
    threeObj.current && window.addEventListener('resize', resizeUpdate)

    const modelInit = (equipmentType) => {
        try {
            loadGLTFMod(equipmentType).then((res) => {
                const photocatalysisObj = res.scene.children[0]
                photocatalysisObj.receiveShadow = true;
                photocatalysisObj.castShadow = true;

                let tempReactionArr = []
                let tempInjectBotArr = []
                let tempPeristalticBotArr = []
                let tempInjectPump = []
                let tempPeristalticPump = []

                // photocatalysisObj.children[0].children.forEach(item => {
                //     if (item.name == 'frame') {
                // let geometryArr = []
                // photocatalysisObj.children[0].remove(item)
                // item.traverse(frameChild => {
                //     if (frameChild.geometry) {
                //         let matrixWorldGeometry = new THREE.BufferGeometry().fromBufferGeometry(frameChild.geometry)
                //         //这个时候 matrixWorldGeometry 几何体的位置就已经转化成世界坐标的位置了
                //         console.log(matrixWorldGeometry);
                //         geometryArr.push(matrixWorldGeometry)
                //     }
                // })
                // console.log(item);
                // let materialArr = [...Array(geometryArr.length)].map(x => item.children[0].children[0].material);
                // const mergedGeometries = mergeGeometries(geometryArr, true);
                // const singleMergeMesh = new THREE.Mesh(mergedGeometries, materialArr);
                // photocatalysisObj.children[0].add(singleMergeMesh)


                // } else {
                photocatalysisObj.traverse(obj => {
                    obj.castShadow = true;

                    obj['originPosition'] = obj.position
                    let nameArr = obj.name.split('-')
                    if (nameArr[0] === 'bot') {
                        let labelObj = css2DLabel(labelDiv.current)
                        labelObj.position.y = 300
                        labelObj.element.innerText = `${nameArr[2]}号`
                        let tempObj = { number: nameArr[2], modType: 'bot', notClick: false, botType: nameArr[1] }
                        labelObj.layers.mask = 0
                        obj.add(labelObj)

                        if (nameArr[1] == 'peristaltic') {
                            tempObj['moduleName'] = 'B1'
                            tempPeristalticBotArr[nameArr[2] * 1 - 1] = obj
                        } else if (nameArr[1] == 'inject') {
                            tempObj['moduleName'] = 'A1'
                            tempInjectBotArr[nameArr[2] * 1 - 1] = obj
                        }
                        Object.assign(obj, tempObj)
                        obj.traverse(child => {
                            child => child.moduleName = moduleName
                            Object.moduleName = moduleName
                            Object.assign(child, tempObj)
                            child.renderOrder = 1
                        })
                        // obj.children[0].material = new THREE.MeshPhysicalMaterial({
                        //     metalness: 0.0,//玻璃非金属  金属度设置0
                        //     roughness: 0.0,//玻璃表面光滑  
                        //     envMapIntensity: 1,
                        //     emissive: 'rgb(80,80,80)',
                        //     ior: 2,//折射率
                        //     transmission: 1, //玻璃材质透光率，transmission替代opacity 
                        // })

                    } else if (nameArr[0] === 'pump') {
                        obj.modType = 'module'
                        // console.log(nameArr[0], nameArr[1], nameArr[3]);
                        if (nameArr[1] == 'inject') {
                            tempInjectPump.push(obj)
                            obj.moduleName = 'injectPump'

                        } else if (nameArr[1] == 'peristaltic') {
                            tempPeristalticPump.push(obj)
                            obj.moduleName = 'peristalticPump'

                        }
                    } else if (nameArr[0] == 'reactionStill') {
                        obj.modType = 'module'
                        obj.moduleName = 'C1'
                        tempReactionArr.push(obj)
                    }
                    if (obj.isMesh) {
                        obj.material.side = THREE.DoubleSide
                        obj.material = obj.material.clone()
                        obj.originMaterial = obj.material.clone()
                        obj.frustumCulled = false;
                    }

                })
                // }
                // })

                settingModel.current = photocatalysisObj
                threeObj.current.scene.add(photocatalysisObj)
                injectBot = tempInjectBotArr
                peristalticBot = tempPeristalticBotArr
                reaction = tempReactionArr
                injectPump = tempInjectPump
                // console.log(injectBot, peristalticBot);
                peristalticPump = tempPeristalticPump
                setModelLoaded(1)
                props.setScene(threeObj.current)
                // B1.current = newCube([-1, 4, 0], 'B1', 2)
                // A1.current = newCube([-1, 6.2, 0], 'A1', 5)
                threeObj.current.scene.add(A1.current)
                threeObj.current.scene.add(B1.current)
                threeObj.current.addGround()
                threeObj.current.onWindowResize()
            })
        } catch (error) {
            console.log(error)

        }

    }

    const newCube = (position, name, total) => {
        let geometry = new THREE.BoxGeometry(2.5, 2.5, 2.5);
        let material = new THREE.MeshBasicMaterial({
            color: 0xff0000,
        });
        // cube 是一个可以填充的形状
        let cube = new THREE.Mesh(geometry, material);
        cube.material = opacityMaterial.clone()
        let tempObj = { modType: 'module', moduleName: name, number: '' }

        let group = new THREE.Object3D()
        Object.assign(group, tempObj)

        group.visible = false
        group.total = total
        group.add(cube)
        group.position.set(...position)
        return group

    }

    useEffect(() => {
        if (modelLoaded) {
            props.initModelLoaded()
        }
    }, [modelLoaded])


    useEffect(() => {
        return () => {
            originMaterial = null
            window.removeEventListener('resize', resizeUpdate)
            threeObj.current?.scene?.clear();
        }
    }, [])
    useEffect(() => {
        threeObj.current && threeObj.current.onWindowResize()
    }, [props.current, props.panelNum])


    useEffect(() => {
        threeObj.current = new threeInit({ ref: cfRef, labelDiv: labelDiv })
    }, [])


    const handleOnClick = (e) => {
        if (props.current == 2) {
            return
        }
        let attriObj = threeObj.current.onPointerClick(e, 'ML', threeObj.current.canvas.current)
        // console.log("🚀 ~ file: index.jsx:193 ~ handleOnClick ~ e:", attriObj)
        let clickObj = { type: '', moduleName: '', number: '', total: '' }

        if (attriObj && (!attriObj.notClick)) {
            // console.log(attriObj.parent);
            const { number, modType, moduleName, total } = attriObj.parent
            clickObj.type = modType
            clickObj.number = number + ''
            clickObj.moduleName = moduleName
            clickObj.total = total
        }
        props.handleModelClick(clickObj)
    }

    const handelonHover = (e) => {
        if (props.current == 2 || !modelLoaded) {
            return
        }
        let attriObj = threeObj.current.onPointerClick(e, 'ML', threeObj.current.canvas.current)
        // A1.current.visible = false
        // B1.current.visible = false
        // console.log("reaction------------", reaction[0]['lastColor']);

        let botArr = { 'A1': injectBot, 'B1': peristalticBot, 'C1': reaction, }
        for (let module in botArr) {
            botArr[module].forEach((item, index) => {
                if (module == 'C1' && index > 0) return
                let color = module == 'C1' ? item.lastColor : item.children[0]['lastColor']
                // console.log(item);
                item.children.at(-1).layers.mask = 0
                setColor({ equipmentType: '4', module, number: index + 1, color: 'origin' })
                color && setColor({ equipmentType: '4', module, number: index + 1, color: color })
            })
        }

        setModuleName(null)

        if (attriObj && (!attriObj.notClick)) {
            const { moduleName } = attriObj.parent
            switch (moduleName) {
                case 'A1':
                case 'B1':
                    // A1.current.visible = true
                    attriObj.parent.children[0].traverse(child => {
                        if (child.geometry) {
                            child.material = opacityMaterial.clone()
                        }
                    })
                    attriObj.parent.children.at(-1).layers.mask = 1
                    setModuleName(moduleName)
                    break;
                case 'C1':
                    reaction.forEach(itemObj => {
                        itemObj.traverse(child => {
                            if (child.geometry) {
                                child.material = opacityMaterial.clone()
                            }
                        })
                    })
                    setModuleName('C1')
                    break;
                default:
                    break;
            }

        } else {
            setModuleName(null)
        }

    }

    return (
        <div className={`${styles.cfMain} modelBg`}>
            <div ref={cfRef} className={styles.cfRef} onClick={handleOnClick} onMouseMove={handelonHover}>
            </div>

            <div className={styles.labelDiv} ref={labelDiv}>
            </div>
            {
                moduleName && moduleFunMap?.current[moduleName] && <div className={styles.displayPanel} >

                    <div className={styles.panelContent}>
                        <p className={styles.title}>模块名称：</p>
                        <p className={styles.moduleName}>
                            {moduleFunMap.current[moduleName]?.name}
                        </p>
                        <p className={styles.title}>可执行操作：</p>
                        <Space size={[0, 8]} wrap className={styles.moduleFun}>
                            {moduleName && moduleFunMap.current[moduleName].fun?.map(item =>
                                <Tag color="blue" key={nanoid()}>{item}</Tag>
                            )}
                        </Space>
                    </div>
                </div>
            }

        </div>
    )
}

export { originMaterial, reaction, injectBot, peristalticBot, injectPump, peristalticPump }