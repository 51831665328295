import { mAObjs, mBObjs, mCObjs, mC2Objs, mDObjs, mEObjs, moduleGroup, COLORSOLID, solidModuleGroup } from "./commonModel"
import { botPositionYMap } from './hardWareConfig'
import { gsap } from "gsap"
import * as THREE from 'three'


let MLHOVER = null
/**
 * 
 * @param {*} module 模块类型 A B C C1 D E F lcms色质谱 roman拉曼 ultrasound超声 wasteLiquid废液槽
 * @param {*} col 列
 * @param {*} row 行
 * @param {*} color 颜色
 */
function setColor(module, col, row, color) {
    try {
        if (col !== 0) {
            let seleObj = paramMap(module, col, row)
            paramMap(module, col, row).lastColor = color
            // console.log(mAObjs);
            seleObj.traverse(child => {
                child.geometry && child.material.color.set(color) && (child.material.lastColor = color) && child.material.emissive.set(color)
            })
        } else {
            let changeObj = Object.assign({}, moduleGroup, solidModuleGroup)
            // console.log(changeObj);
            let selectedObj = changeObj[module]
            if (selectedObj) {
                selectedObj.lastColor = color
                // console.log(selectedObj);
                let bigParent = findTopParent(selectedObj)
                // console.log(bigParent);

                bigParent.traverse((child) => {
                    if (child.geometry && child.modType === 'module') {
                        child.material.color.set(color)
                        child.material.emissive.set(color)

                        child.lastColor = color
                    }
                })
            }

        }
    } catch (error) {
        console.log(error);
    }

}

function setLabelConent(module, col, row, content) {
    try {
        let seleObj = paramMap(module, col, row)
        seleObj.children.at(-1).element.innerText = content
    } catch (error) {
        console.log(error);
    }

}

function paramMap(module, row, col,) {
    try {
        let index = 0
        switch (module) {
            case 'A':
                index = (col * 1 - 1) * 3 + row * 1 - 1
                return mAObjs[index]
            case 'B':
                index = (col * 1 - 1) * 3 + row * 1 - 1
                return mBObjs[index]
            case 'C':
                index = (col * 1 - 1) * 2 + row * 1 - 1
                return mCObjs[index]
            case 'C2':
                index = (col * 1 - 1) * 6 + row * 1 - 1
                return mC2Objs[index]
            case 'D':
                index = (col * 1 - 1) * 3 + row * 1 - 1
                return mDObjs[index]
            case 'E':
                index = (col * 1 - 1) * 3 + row * 1 - 1
                return mEObjs[index]

            default:
                break;
        }
    } catch (error) {
        console.log(error);
    }


}

function handlePointerEenter(steps, e, MLStation, setHoverModule) {
    e.preventDefault();
    try {
        const selectedObj = MLStation.onPointerClick(e, 'ML')
        if (selectedObj) {
            const { parent } = selectedObj
            if (steps !== 2 && (selectedObj.modType == 'bot' || (!parent?.parent?.click) || (parent && !parent.click))) {
                if (selectedObj.name !== MLHOVER) {
                    modelColorRestore()
                }
                let changeMod
                if (parent.type === "Scene") {
                    changeMod = selectedObj
                } else if (parent.parent.parent && parent.parent.parent.type !== 'Scene') {
                    changeMod = parent.parent.parent
                } else if (parent.parent && parent.parent.type !== 'Scene') {
                    changeMod = parent.parent
                }
                setHoverModule(changeMod.name)
                changeMod.traverse((child) => {
                    MLHOVER = selectedObj.name
                    if (!child.click && child.geometry && child.modType === 'module') {
                        child.material.color.set(0x00ff00)
                    }
                })

                selectedObj.modType === 'bot' && selectedObj.traverse((child) => {
                    MLHOVER = selectedObj.name
                    // setColor('C', '1', '1', 0xff0000)
                    botReset({ color: 'back', labelVisiable: 0 })

                    selectedObj.parent.children[1].layers.mask = 1
                    // console.log(selectedObj.parent);

                    if (child.geometry && child.modType === 'bot') {
                        child.material.color.set(0x00ff00)
                        child.material.emissive.set(0x00ff00)

                    }
                })

            }
        } else {
            modelColorRestore()
            setHoverModule(null)
            botReset({ color: 'back', labelVisiable: 0 })
        }

    } catch (error) {
        console.log(error);
    }
}

function modelColorRestore() {
    try {
        for (let key in moduleGroup) {
            if (!moduleGroup[key].click) {
                moduleGroup[key].lastColor !== undefined ? moduleColorChange(moduleGroup[key], moduleGroup[key].lastColor) : moduleColorChange(moduleGroup[key], COLORSOLID)
            }
        }
        for (let key in solidModuleGroup) {
            if (!solidModuleGroup[key].click) {
                solidModuleGroup[key].lastColor !== undefined ? moduleColorChange(solidModuleGroup[key], solidModuleGroup[key].lastColor) : moduleColorChange(solidModuleGroup[key], COLORSOLID)
            }
        }
    } catch (error) {
        console.log(error);
    }

}

function moduleColorChange(module, color) {
    try {
        module.traverse(child => {
            if (child.geometry && child.modType !== 'bot') {
                child.material.color.set(color)
            }
        })
    } catch (error) {
        console.log(error);
    }

}

function botPutBack(module, col, row) {
    try {
        paramMap(module, col, row).position.y = botPositionYMap[module]
        paramMap(module, col, row).children[1].layers.set(1)
    } catch (error) {
        console.log();
    }

}

function botPutUp(module, col, row) {
    try {
        if (paramMap(module, col, row)) {
            paramMap(module, col, row).position.y = botPositionYMap[module] + 5
        }
    } catch (error) {
        console.log(error);
    }

}

function moduleGrap(grap, module) {
    try {
        let t1 = gsap.timeline()
        let { position } = moduleGroup["E"]
        if (grap) {
            switch (module) {
                case 'E':
                    grabUp(34.9, -3, 1.5)
                    break;
                case 'E1':
                    grabUp(22.9, -3, 2.5)
                    break;
                case 'E2':
                    grabUp(22.9, -3, 0.5)
                    break;
                default:
                    break;
            }
            // setModelVisiable(mDObjs, true)
        } else {
            switch (module) {
                case 'E':
                    grabDown(34.9, -13, 1.5)
                    setModelVisiable(mDObjs, true)
                    changeEName('E')
                    break;
                case 'E1':
                    grabDown(22.9, -10, 2.5)
                    setModelVisiable(mDObjs, false)
                    changeEName('E1')
                    break;
                case 'E2':
                    grabDown(22.9, -10, -0.5)
                    setModelVisiable(mDObjs, false)
                    changeEName('E2')
                    break;
                default:
                    break;
            }

        }

        function grabUp(distX, distY, distZ) {
            t1.to(position, {
                y: distY, duration: 0.3, onComplete: () => {
                    t1.to(position, {
                        x: distX, duration: 0.3, onComplete: () => {
                            t1.to(position, { z: distZ, duration: 0.3 })
                        }
                    })
                }
            })
        }
        function grabDown(distX, distY, distZ) {
            t1.to(position, {
                x: distX, duration: 0.5, onComplete: () => {
                    t1.to(position, { y: distY, z: distZ, duration: 0.5 })
                }
            })
        }
        function setModelVisiable(mArr, visible) {
            mArr.forEach(objItem => {
                objItem.traverse(child => {
                    child.visible = visible
                })
            })
        }
        function changeEName(moduleName) {
            moduleGroup['E'].name = moduleName
            moduleGroup['E'].traverse(item => {
                item.modType !== 'bot' && (item.name = moduleName)
            })
            if (moduleName === 'E') {
                moduleGroup['D'].modType = 'module'
                mDObjs.forEach(item => {
                    item.modType = 'bot'
                    item.traverse(dItem => {
                        dItem.modType = 'bot'
                    })
                })
                moduleGroup['D'].traverse(item => {
                    item.modType !== 'bot' && (item.modType = 'module')
                })

            } else {
                // moduleGroup['D'].name = moduleName
                // moduleGroup['D'].modType = ''
                // moduleGroup['D'].traverse(item => {
                //     item.modType !== 'bot' && (item.modType = '')
                // })
                mDObjs.forEach(item => {
                    item.modType = ''
                    item.traverse(itemD => itemD.modType = '')
                })
            }
            mEObjs.forEach(item => {
                let nameArr = item.name.split('-')
                item.name = `${moduleName}-${nameArr[1]}-${nameArr[2]}`
                item.children.at(-1).element.innerText = item.name
                item.traverse(child => child.name = item.name)
            })
        }
    } catch (error) {
        console.log(error);
    }

}
const cloneObj = (pre) => {
    try {
        let obj = new THREE.Object3D()
        pre.traverse((child) => {
            let tempMesh
            if (child.geometry) {
                tempMesh = child.clone()
                delete tempMesh.material;
                delete tempMesh.position;
                tempMesh.material = child.material.clone()
                tempMesh.position = child.position.clone()
                obj.add(tempMesh)
            }
        })
        return obj
    } catch (error) {
        console.log(error);
    }

}

const transLineMode = (object) => {
    let buildMaterial = new THREE.MeshBasicMaterial({
        color: 0x00BFFF,     // 颜色
        transparent: true,    // 是否开启使用透明度
        opacity: 0.3,        // 透明度
        depthWrite: true,    // 关闭深度写入 透视效果
        side: THREE.DoubleSide, // 双面显示
    });

    object.traverse((obj) => {
        // 由于模型由许多mesh组成，因此需要将所有的mesh都转换为EdgesGeometry材质
        obj.geometry && (obj.material = buildMaterial);
        // if (obj.type === 'Mesh') edgeGroup.add(_renderFrameMesh(obj));
    });


}

const findTopParent = (selectedObj) => {
    let bigParent = selectedObj
    if (selectedObj && selectedObj.parent) {
        const { parent } = selectedObj
        if (parent.type == 'Scene') {
            bigParent = selectedObj
        } else if (parent.parent.type == 'Scene') {
            bigParent = parent
        } else if (parent.parent.parent.type == 'Scene') {
            bigParent = parent.parent
        } else if (parent.parent.parent.paren.type == 'Scene') {
            bigParent = parent.parent.parent
        }
    }
    return bigParent
}


function botReset(paramObj) {
    let allBots = { 'A': mAObjs, 'B': mBObjs, 'C': mCObjs, 'C2': mC2Objs, 'D': mDObjs, 'E': mEObjs }
    // modelReset(allBots, paramObj, COLORSOLID)
    for (let key in allBots) {
        allBots[key].forEach(item => {
            modelReset(item, paramObj, COLORSOLID)
        })
    }
}

/**
 * 
 * @param {*} paramObj :对所有的瓶子进行统一设置
 * @param {*} paramObj.position:true :对所有的瓶子进行统一设置
 * @param {*} paramObj.color:'back'- 返回上一层设置的颜色
 * @param {*} paramObj.color:'reset'- 变成原来的颜色
 * @param {*} paramObj.labelVisiable:true - 标签可见
 * @param {*} paramObj.labelVisiable:false - 标签不可见
 * 
 */
function modelReset(model, paramObj, originColor) {

    let lastColor = model.lastColor
    let positionRest = botPositionYMap[model?.name?.split('-')[0]]
    positionRest && (model.position.y = positionRest)

    paramObj.color === 'reset' && model.traverse(child => {
        child.geometry && child.material.color.set(originColor)
    })
    paramObj.color === 'back' && model.traverse(child => {
        if (child.geometry) {
            // child.material.lastColor && (lastColor = child.material.lastColor)

            if (lastColor) {
                child.material.color.set(lastColor)
                child.material.emissive.set(lastColor)

            } else if (originColor) {
                child.material.color.set(originColor)
                child.material.emissive.set(0xC0C0C0)

            } else {
                child.material.emissive.set(0xC0C0C0)
                child.material = child.originMaterial
            }

        }


    })
    paramObj.labelVisiable !== undefined && (paramObj.labelVisiable || (model.children[1].layers.mask = 0))
    paramObj.labelVisiable && (model.children[1].layers.mask = 1)


}

export { setColor, setLabelConent, handlePointerEenter, botPutBack, botPutUp, moduleGrap, cloneObj, transLineMode, findTopParent, moduleColorChange, botReset, modelReset, modelColorRestore }