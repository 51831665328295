import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// 翻译资源
import enTranslations from './locales/en-US.js';
import zhTranslations from './locales/zh-CN.js';

const resources = {
    'en-US': {
        translation: enTranslations,
    },
    'zh-CN': {
        translation: zhTranslations,
    },
};

i18n.use(initReactI18next) // 使用react-i18next
    .init({
        resources,
        lng: window.localStorage.getItem('language') || 'zh-CN', // 默认语言
        keySeparator: false, //  we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;